import axios from 'axios';
import { urls as urls, generateToken, urlType } from '../api/urls';
import _ from 'lodash';
import * as couponActions from './couponActions';
import { updateRideDistance } from './rideActions';
import { logTransaction } from './orderActions';
import queryString from "query-string"


export function updateCars(data) {
    return {
        type: 'UPDATE_CARS',
        data: data
    }
}

export function updateVendors(data) {
    return {
        type: 'UPDATE_VENDORS',
        data
    }
}

export function flushCarsState() {
    return {
        type: 'FLUSH_CARS_STATE'
    }
}

export function chauffeurCarsLoadingTrue() {
    return {
        type: 'CHAUFFEUR_CARS_LOADING_TRUE'
    }
}

export function chauffeurCarsLoadingFalse() {
    return {
        type: 'CHAUFFEUR_CARS_LOADING_FALSE'
    }
}


export function selfDriveLoadingTrue() {
    return {
        type: 'SELF_DRIVE_LOADING_TRUE'
    }
}

export function selfDriveLoadingFalse() {
    return {
        type: 'SELF_DRIVE_LOADING_FALSE'
    }
}


export function chauffeurCarsAvailableTrue() {
    return {
        type: 'CHAUFFEUR_CARS_AVAILABLE_TRUE'
    }
}

export function chauffeurCarsAvailableFalse() {
    return {
        type: 'CHAUFFEUR_CARS_AVAILABLE_FALSE'
    }
}


export function selfDriveAvailableTrue() {
    return {
        type: 'SELF_DRIVE_AVAILABLE_TRUE'
    }
}

export function selfDriveAvailableFalse() {
    return {
        type: 'SELF_DRIVE_AVAILABLE_FALSE'
    }
}


export function vendorsLoadingTrue() {
    return {
        type: 'VENDORS_LOADING_TRUE'
    }
}

export function vendorsLoadingFalse() {
    return {
        type: 'VENDORS_LOADING_FALSE'
    }
}

export function vendorsAvailableTrue() {
    return {
        type: 'VENDORS_AVAILABLE_TRUE'
    }
}

export function vendorsAvailableFalse() {
    return {
        type: 'VENDORS_AVAILABLE_FALSE'
    }
}

export function airportCarsLoadingTrue() {
    return {
        type: 'AIRPORT_CARS_LOADING_TRUE'
    }
}

export function airportCarsLoadingFalse() {
    return {
        type: 'AIRPORT_CARS_LOADING_FALSE'
    }
}

export function airportCarsAvailableTrue() {
    return {
        type: 'AIRPORT_CARS_AVAILABLE_TRUE'
    }
}

export function airportCarsAvailableFalse() {
    return {
        type: 'AIRPORT_CARS_AVAILABLE_FALSE'
    }
}

export function rentalCarsLoadingTrue() {
    return {
        type: 'RENTAL_CARS_LOADING_TRUE'
    }
}

export function rentalCarsLoadingFalse() {
    return {
        type: 'RENTAL_CARS_LOADING_FALSE'
    }
}

export function rentalCarsAvailableTrue() {
    return {
        type: 'RENTAL_CARS_AVAILABLE_TRUE'
    }
}

export function rentalCarsAvailableFalse() {
    return {
        type: 'RENTAL_CARS_AVAILABLE_FALSE'
    }
}

export function updateAirportCars(data) {
    return {
        type: 'UPDATE_AIRPORT_CARS',
        data: data
    }
}

export function updateRentalCars(data) {
    return {
        type: 'UPDATE_RENTAL_CARS',
        data: data
    }
}

export function showRating(data) {
    return {
        type: 'SHOW_RATING',
        data: data
    }
}

//fetches car categories for Chauffeur Driven
export function fetchVehicleCategories() {
    return function (dispatch, getState) {
        
        dispatch(chauffeurCarsLoadingTrue());
        let url = urls.chauffeurCategories;
        let ride = getState().ride;
        //airport changes
        // let isAirport= ride.pickup.loc.includes("Airport") || ride.pickup.loc.includes("airport") || ride.dest.loc.includes("Airport") || ride.dest.loc.includes("airport") ? true : false;
        let isAirport=false;
        let data = {
            "pickupLocation": {
                "latitude":  ride.pickup.cityLat,
                "longitude": ride.pickup.cityLng
            },
            "destinationLocation": {
                "latitude":  isAirport ? ride.dest.locLat  :ride.dest.cityLat,
                "longitude": isAirport ? ride.dest.locLng  :ride.dest.cityLng
            },
            "ride": {
                "measureLookupId": isAirport ? 100010008 : ride.measureLookupId,
                "rideStartDate": ride.startDate,
                "rideEndDate": ride.endDate,            
            }
        }

        if(data.ride.measureLookupId === '100010011'){
            let endData = ride.startDate.split(' ')[0];
            data.ride.rideEndDate = endData + ' 23:59:00'
        }
        // console.log(data);
        axios.post(url, data)
            .then(response => {
                if (response.data.statusCD === 200 && response.data.responseData.length > 0) {
                    // console.log(response);
                    dispatch(chauffeurCarsLoadingFalse());
                    let updatedData = [];
                    response.data.responseData.forEach(data => {
                        if (data.isAvailable >= 1) {
                            updatedData.push(data);
                        }
                    });
                    if (updatedData.length > 0) {
                        dispatch(updateCars(updatedData));
                        dispatch(chauffeurCarsAvailableTrue());
                    }
                    else {
                        dispatch(updateCars([]));
                        dispatch(chauffeurCarsAvailableFalse());
                    }

                }
                else {
                    dispatch(chauffeurCarsLoadingFalse());
                    dispatch(updateCars([]));
                    dispatch(chauffeurCarsAvailableFalse());
                }
            })
            .catch(err => {
                dispatch(chauffeurCarsLoadingFalse());
                dispatch(updateCars([]));
                dispatch(chauffeurCarsAvailableFalse());
            })

    }
}
export function logMessage(text){

    let currentDate = new Date();
    let url = urls.logMessage; 
    let data=text+' '+currentDate;   
    axios.post(url, data)
    .then(response => {
        if (response.status === 200 ) {
        console.log('logsent to the backend'+text+' '+currentDate);
        }
        else {
            console.log('failed to send log to the backend'+text+' '+currentDate);
        }
    })
    .catch(err => {
        console.log(err);
    })
}


//fetches vendors for a specific category chuaffuer driven
export function fetchPricing(categoryId) {
    return function (dispatch, getState) {
        
        dispatch(vendorsLoadingTrue());
        let ride = getState().ride;
        let url = urls.vehiclesUrl;
        // airport change
        // let isAirport= ride.pickup.loc.includes("Airport") || ride.pickup.loc.includes("airport") || ride.dest.loc.includes("Airport") || ride.dest.loc.includes("airport") ? true : false;
        let isAirport=false;
        let data = {
            "pickupLocation": {
                "latitude":  ride.pickup.cityLat,
                "longitude": ride.pickup.cityLng
            },
            "destinationLocation": {
                "latitude":  isAirport ? ride.dest.locLat  :ride.dest.cityLat,
                "longitude": isAirport ? ride.dest.locLng  :ride.dest.cityLng
            },
            "stops": ride.stops,
            "ride": {
                "measureLookupId": isAirport ? 100010008 : ride.measureLookupId,
                "categoryId": categoryId,
                "rideStartDate": ride.startDate,
                "rideEndDate": ride.endDate,
                "distance": ride.distance
                // "rideEndDate": "26-10-2018 18:00:00"            
            }
        }
        //oneway time is 11;59 pm 
        if(data.ride.measureLookupId === '100010011'){
            let endData = ride.startDate.split(' ')[0];
            data.ride.rideEndDate = endData + ' 23:59:00'
        }
        dispatch(updateVendors([]));
        logMessage('fetchVehicleDetails Chauffeur Driven Call Started');

        axios.post(url, data)
            .then(response => {
                dispatch(vendorsLoadingFalse());
                if (response.data.statusCD === 200 && response.data.responseData.length > 0) {
                    
                    logMessage('fetchVehicleDetails Chauffeur Driven Call Succeeded');
                    console.log(response);
                    dispatch(updateVendors(response.data.responseData));
                    dispatch(vendorsAvailableTrue());
                }
                else {
                    logMessage('fetchVehicleDetails Chauffeur Driven Call Failed');
                    dispatch(updateVendors([]));
                    dispatch(vendorsAvailableFalse());
                }
            })
            .catch(err => {
                logMessage('fetchVehicleDetails Chauffeur Driven Call Failed');
                dispatch(vendorsLoadingFalse());
                dispatch(updateVendors([]));
                dispatch(vendorsAvailableFalse());
            })
    }
}


//fetches complete data
export function fetchChauffeurVehicleDetails(token) {
    return function (dispatch, getState) {
        
        dispatch(couponActions.fetchCoupons());
        // dispatch(chauffeurCarsLoadingTrue());
        let ride = getState().ride;
        let url = urls.vehiclesPricing;
        // airport change
        // let isAirportPickup = ride.pickup.loc.includes("Airport") || ride.pickup.loc.includes("airport") ? true : false;
        // let isAirportDrop = ride.dest.loc.includes("Airport") || ride.dest.loc.includes("airport") ? true : false;
        // let isAirport=false;
        let data = {
            "pickupLocation": {
                "latitude":  ride.pickup.cityLat,
                "longitude": ride.pickup.cityLng
            },
            "destinationLocation": {
                "latitude":  ride.dest.cityLat,
                "longitude": ride.dest.cityLng
            },
            "stops": ride.stops,
            "ride": {
                "measureLookupId": ride.measureLookupId,
                "rideStartDate": ride.startDate + " " + ride.startTime,
                "rideEndDate": ride.endDate + " " + ride.endTime,
                "distance": ride.distance          
            }
        }
        console.log(data);
        //oneway time is 11:59 pm 
        if(data.ride.measureLookupId === '100010011'){
            data.ride.rideEndDate = ride.startDate + ' 23:59:00'
        }
        // else if(data.ride.measureLookupId === "100010001" && data.stops.length < 3){
        //     data.ride.distance = ride.distance * 2;
        //     // dispatch(updateRideDistance(ride.distance * 2));
        //     // dispatch(addStop(ride.stops[0]));
        // }
        let config = {};
        if (token !== '') {
            config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        }
        console.log(data);
        logMessage('fetchVehicleDetails Chauffeur Driven Call Started');
        axios.post(url, data, config)
            .then(response => {
                console.log(response);
                
                dispatch(chauffeurCarsLoadingFalse());
                if (response.data.statusCD === 200 && response.data.responseData.length > 0) {
                    
                    logMessage('fetchVehicleDetails Chauffeur Driven Call Succeeded');
                    dispatch(logTransaction('fetchVehicleDetails Chauffeur Driven',data));
                    let cars=_.cloneDeep(response.data.responseData);
                    let updatedCars=[];
                    let coupons=_.orderBy(getState().coupons.all, [coupon => coupon.discountValue], ['desc']);

                    if(coupons.length<=0){
                        // dispatch(updateCars(response.data.responseData));
                        cars.forEach(car => {
                            let vendors=[];
                            car.vendors.forEach(vendor => {
                                console.log("vendor i", vendor);
                                let newObj=Object.assign({},vendor,{ discountedValue:vendor.ride_amt,coupon:null,couponValue:0});                                        
                                vendors.push(newObj);
                                console.log("vendor a", newObj);
                            })
                            let obj=Object.assign({},car,{vendors});
                            updatedCars.push(obj);
                        })
                        console.log("Updated Cars", updatedCars)
                        dispatch(updateCars(updatedCars));
                    }

                    else{
                        cars.forEach(car => {
                            let vendors=[];
                            car.vendors.forEach(vendor => {
                                let couponApplied=false;
                                for(let i=0;i<coupons.length && !couponApplied;i++){
                                        //apply to specific vendor
                                    if (coupons[i].hasOwnProperty('measureLookups')){
                                        if(coupons[i].hasOwnProperty('accountId')){
                                            if((coupons[i].accountId===vendor.account_id ) && (vendor.ride_amt>=coupons[i].minAmount  && coupons[i].measureLookups.includes(vendor.measure_lookup_id))){
                                            let newObj=Object.assign({},vendor,{ discountedValue:vendor.ride_amt-coupons[i].discountValue,coupon:coupons[i].name,couponValue:coupons[i].discountValue })
                                            vendors.push(newObj);
                                            couponApplied=true;
                                            console.log("vendor 1", newObj);
                                            }
                                        }
                                        //Apply to all Vendors
                                        else{
                                            if((vendor.ride_amt>=coupons[i].minAmount)  && coupons[i].measureLookups.includes(vendor.measure_lookup_id)){
                                                let newObj=Object.assign({},vendor,{ discountedValue:vendor.ride_amt-coupons[i].discountValue,coupon:coupons[i].name,couponValue:coupons[i].discountValue});
                                                vendors.push(newObj); 
                                                couponApplied=true;
                                                console.log("vendor 2", newObj)
                                            }
                                        }
                                    }
                                }
                                if(!couponApplied){
                                    let newObj=Object.assign({},vendor,{ discountedValue:vendor.ride_amt,coupon:null,couponValue:0});                                        
                                    vendors.push(newObj);
                                    console.log("vender 3", newObj)
                                }               
                            });
                            let obj=Object.assign({},car,{vendors});
                            updatedCars.push(obj);
                            console.log(obj);
                        });
                        console.log("Updated Cars", updatedCars)
                        dispatch(updateCars(updatedCars));
                    }
                    
                    dispatch(chauffeurCarsAvailableTrue());
                }
                else {
                    logMessage('fetchVehicleDetails Chauffeur Driven Call Failed');
                    dispatch(logTransaction('fetchVehicleDetails Chauffeur Driven Call Failed',data));
                    dispatch(updateCars([]));
                    dispatch(chauffeurCarsAvailableFalse());
                }
            })
            .catch(err => {
                
                console.log(err);
                logMessage('fetchVehicleDetails Chauffeur Driven Call Failed');
                dispatch(chauffeurCarsLoadingFalse());
                dispatch(updateCars([]));
                dispatch(chauffeurCarsAvailableFalse());
            })
    }
}



/*START Self Drive Vehicles*/

export function updateSelfDriveCars(data) {
    return {
        type: 'UPDATE_SELFDRIVE_CARS',
        data: data
    }
}

//fetches complete data
export function fetchSelfDriveVehicleDetails(token) {
    return function (dispatch, getState) {
        dispatch(couponActions.fetchCoupons());
        // dispatch(selfDriveLoadingTrue());
        let url = urls.vehiclesPricing;
        let ride = getState().ride;
        let data = {
            "pickupLocation": {
                "latitude": ride.pickup.cityLat,
                "longitude": ride.pickup.cityLng
            },
            // "pickupLocation": {
            //     "latitude": 17.385044,
            //     "longitude": 78.486671
            // },
            "ride": {
                // "categoryId": 100090053,
                "rideStartDate": ride.startDate + " " + ride.startTime,
                "rideEndDate": ride.endDate + " " + ride.endTime
            }
        }
        let config = {};
        if (token !== '') {
            config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        }
        axios.post(url, data, config)
            .then(response => {
                console.log(response);
                dispatch(selfDriveLoadingFalse());
                if (response.data.statusCD === 200 && response.data.responseData.length > 0) {
                    
                    logMessage('fetchVehiclePricing SelfDrive Call Succeeded');
                    dispatch(logTransaction('fetchVehiclePricing SelfDrive',data));
                    let cars=response.data.responseData;
                    let updatedCars=[];
                    let coupons=_.orderBy(getState().coupons.all, [coupon => coupon.discountValue], ['desc']);
                    console.log('cars',cars);
                    let carsList = _.cloneDeep(response.data.responseData);
                    carsList.map(car => {
                        _.remove(car.vendors, function(vendor) {
                            return vendor.hasOwnProperty('is_active') && vendor.is_active === 0;
                          });
                    });
                    cars = carsList;
                        _.remove(cars, function(car) {
                            return car.hasOwnProperty('vendors') && car.vendors.length === 0;
                          });
                    console.log('filtered cars with is_Active === 1',cars);

                    if(coupons.length<=0){
                        // dispatch(updateSelfDriveCars(response.data.responseData));
                        cars.forEach(car => {
                            let vendors=[];
                            car.vendors.forEach(vendor => {
                                let newObj=Object.assign({},vendor,{ discountedValue:vendor.base_fare,coupon:null,couponValue:0});                                        
                                vendors.push(newObj);
                            })
                            let obj=Object.assign({},car,{vendors});
                            updatedCars.push(obj);
                        })
                        dispatch(updateSelfDriveCars(updatedCars));
                    }

                    else{
                        cars.forEach(car => {
                            let vendors=[];
                            car.vendors.forEach(vendor => {
                                let couponApplied=false;
                                for(let i=0;i<coupons.length && !couponApplied;i++){
                                        //apply to specific vendor
                                    if (coupons[i].hasOwnProperty('measureLookups')){
                                        if(coupons[i].hasOwnProperty('accountId')){
                                            if((coupons[i].accountId===vendor.account_id ) && (vendor.base_fare>=coupons[i].minAmount  && coupons[i].measureLookups.includes(vendor.measure_lookup_id))){
                                            let newObj=Object.assign({},vendor,{ discountedValue:vendor.base_fare-coupons[i].discountValue,coupon:coupons[i].name,couponValue:coupons[i].discountValue })
                                            vendors.push(newObj);
                                            couponApplied=true;
                                            // console.log(newObj);
                                            }
                                        }
                                        //Apply to all Vendors
                                        else{
                                            if((vendor.base_fare>=coupons[i].minAmount)  && coupons[i].measureLookups.includes(vendor.measure_lookup_id)){
                                                let newObj=Object.assign({},vendor,{ discountedValue:vendor.base_fare-coupons[i].discountValue,coupon:coupons[i].name,couponValue:coupons[i].discountValue});
                                                vendors.push(newObj); 
                                                couponApplied=true;
                                            }
                                        }
                                    }
                                }
                                if(!couponApplied){
                                    let newObj=Object.assign({},vendor,{ discountedValue:vendor.base_fare,coupon:null,couponValue:0});                                        
                                    vendors.push(newObj);
                                }               
                            });
                            let obj=Object.assign({},car,{vendors});
                            updatedCars.push(obj);
                            // console.log(obj);
                        });
                        // console.log(updatedCars);
                        dispatch(updateSelfDriveCars(updatedCars));
                    }
                    
                    dispatch(selfDriveAvailableTrue());
                }
                else {
                    logMessage('fetchVehiclePricing SelfDrive Call Failed');
                    dispatch(logTransaction('fetchVehiclePricing SelfDrive Call Failed',data));
                    dispatch(selfDriveLoadingFalse());
                    dispatch(updateSelfDriveCars([]));
                    dispatch(selfDriveAvailableFalse());
                }
            })
            .catch(err => {
                logMessage('fetchVehiclePricing SelfDrive Call Failed');
                dispatch(logTransaction('fetchVehiclePricing SelfDrive Call Failed',data));
                dispatch(selfDriveLoadingFalse());
                dispatch(updateSelfDriveCars([]));
                dispatch(selfDriveAvailableFalse());
            })

    }

}
//fetches complete data
export function oldFetchSelfDriveVehicleDetails() {
    return function (dispatch, getState) {
        dispatch(couponActions.fetchCoupons());
        dispatch(selfDriveLoadingTrue());
        let url = urls.vehiclesPricing;
        let ride = getState().ride;
        let data = {
            "pickupLocation": {
                "latitude": ride.pickup.cityLat,
                "longitude": ride.pickup.cityLng
            },
            // "pickupLocation": {
            //     "latitude": 17.385044,
            //     "longitude": 78.486671
            // },
            "ride": {
                // "categoryId": 100090053,
                "rideStartDate": ride.startDate + " " + ride.startTime,
                "rideEndDate": ride.endDate + " " + ride.endTime
            }
        }
        axios.post(url, data)
            .then(response => {
                console.log(response);
                if (response.data.statusCD === 200 && response.data.responseData.length > 0) {
                    dispatch(selfDriveLoadingFalse());
                    logMessage('fetchVehicleDetails SelfDrive Call Succeeded');
                    dispatch(logTransaction('fetchVehicleDetails SelfDrive Call Succeeded',data));
                    let cars=response.data.responseData;
                    let updatedCars=[];
                    let coupons=_.orderBy(getState().coupons.all, [coupon => coupon.discountValue], ['desc']);

                    if(coupons.length<=0){
                        dispatch(updateSelfDriveCars(response.data.responseData));
                    }
                    else{
                    cars.forEach(car => {
                        let with_fuel=[];
                        let without_fuel=[];
                        if(car.hasOwnProperty('without_fuel')){
                            car.without_fuel.forEach(vendor => {
                                let couponApplied=false;
                                for(let i=0;i<coupons.length && !couponApplied;i++){
                                        //apply to specific vendor
                                    if (coupons[i].hasOwnProperty('measureLookups')){
                                        if(coupons[i].hasOwnProperty('accountId')){
                                            if((coupons[i].accountId===vendor.account_id ) && (vendor.base_fare>=coupons[i].minAmount)){
                                            let newObj=Object.assign({},vendor,{ discountedValue:vendor.base_fare-coupons[i].discountValue,coupon:coupons[i].name,couponValue:coupons[i].discountValue })
                                            without_fuel.push(newObj);
                                            couponApplied=true;
                                            // console.log(newObj);
                                            }
                                        }
                                        //Apply to all Vendors
                                        else{
                                            if((vendor.base_fare>=coupons[i].minAmount)){
                                                let newObj=Object.assign({},vendor,{ discountedValue:vendor.base_fare-coupons[i].discountValue,coupon:coupons[i].name,couponValue:coupons[i].discountValue});
                                                without_fuel.push(newObj); 
                                                couponApplied=true;
                                            }
                                        }
                                    }
                                }
                                if(!couponApplied){
                                    let newObj=Object.assign({},vendor,{ discountedValue:vendor.base_fare,coupon:null,couponValue:0});                                        
                                    without_fuel.push(newObj);
                                }            
                            });
                        }

                        if(car.hasOwnProperty('with_fuel')){
                            car.with_fuel.forEach(vendor => {
                                    let couponApplied=false;
                                    for(let i=0;i<coupons.length && !couponApplied;i++){
                                            //apply to specific vendor
                                        if (coupons[i].hasOwnProperty('measureLookups')){
                                            if(coupons[i].hasOwnProperty('accountId')){
                                                if((coupons[i].accountId===vendor.account_id ) && (vendor.base_fare>=coupons[i].minAmount)){
                                                let newObj=Object.assign({},vendor,{ discountedValue:vendor.base_fare-coupons[i].discountValue,coupon:coupons[i].name,couponValue:coupons[i].discountValue })
                                                with_fuel.push(newObj);
                                                couponApplied=true;
                                                // console.log(newObj);
                                                }
                                            }
                                            //Apply to all Vendors
                                            else{
                                                if((vendor.base_fare>=coupons[i].minAmount)){
                                                    let newObj=Object.assign({},vendor,{ discountedValue:vendor.base_fare-coupons[i].discountValue,coupon:coupons[i].name,couponValue:coupons[i].discountValue});
                                                    with_fuel.push(newObj); 
                                                    couponApplied=true;
                                                }
                                            }
                                        }
                                    } 
                                    if(!couponApplied){
                                        let newObj=Object.assign({},vendor,{ discountedValue:vendor.base_fare,coupon:null,couponValue:0});                                        
                                        with_fuel.push(newObj);
                                    }       
            
                            });
                        }

                        let obj=Object.assign({},car,{with_fuel,without_fuel});
                        updatedCars.push(obj);
                        // console.log(upda);
                      });
                      console.log(updatedCars);

                    dispatch(updateSelfDriveCars(updatedCars));
                    }
                    dispatch(selfDriveAvailableTrue());

                }
                else {
                    logMessage('fetchVehicleDetails SelfDrive Call Failed');
                    dispatch(logTransaction('fetchVehicleDetails SelfDrive Call Failed',data));
                    dispatch(selfDriveLoadingFalse());
                    dispatch(updateSelfDriveCars([]));
                    dispatch(selfDriveAvailableFalse());
                }
            })
            .catch(err => {
                logMessage('fetchVehicleDetails SelfDrive Call Failed');
                dispatch(logTransaction('fetchVehicleDetails SelfDrive Call Failed',data));
                dispatch(selfDriveLoadingFalse());
                dispatch(updateSelfDriveCars([]));
                dispatch(selfDriveAvailableFalse());
            })

    }

}


export function fetchSelfDriveCars() {
    return function (dispatch, getState) {
        dispatch(selfDriveLoadingTrue());
        let url = urls.chauffeurCategories;
        let ride = getState().ride;
        let data = {
            "pickupLocation": {
                "latitude": ride.pickup.cityLat,
                "longitude": ride.pickup.cityLng
            },
            "destinationLocation": {
                "latitude": ride.dest.cityLat,
                "longitude": ride.dest.cityLng
            },
            "ride": {
                "categoryId": 100090053,
                "rideStartDate": ride.startDate,
                "rideEndDate": ride.endDate
            }
        }
        
        axios.post(url, data)
            .then(response => {
                console.log(response);
                if (response.data.statusCD === 200 && response.data.responseData.length > 0) {
                    dispatch(selfDriveLoadingFalse());
                    // let groupedData = _.toArray(_.groupBy(response.data.responseData, resData => resData.sub_category_id));
                    let updatedData = [];
                    response.data.responseData.forEach(data => {
                        if (data.isAvailable >= 1) {
                            updatedData.push(data);
                        }
                    });
                    if (updatedData.length > 0) {
                        dispatch(updateSelfDriveCars(updatedData));
                        dispatch(selfDriveAvailableTrue());
                    }
                    else {
                        dispatch(updateSelfDriveCars([]));
                        dispatch(selfDriveAvailableFalse());
                    }

                }
                else {
                    dispatch(selfDriveLoadingFalse());
                    dispatch(updateSelfDriveCars([]));
                    dispatch(selfDriveAvailableFalse());
                }
            })
            .catch(err => {
                dispatch(selfDriveLoadingFalse());
                dispatch(updateSelfDriveCars([]));
                dispatch(selfDriveAvailableFalse());
            })

    }

}



//fetches vendors for a specific category Self Drive
export function fetchSelfDrivePricing(subCategoryId) {
    
    return function (dispatch, getState) {
        
        dispatch(selfDriveLoadingTrue());
        let ride = getState().ride;
        // let url = urls.vehiclesUrl;
        let url = urls.vehiclesPricing;
        let data = {
            "pickupLocation": {
                "latitude": ride.pickup.cityLat,
                "longitude": ride.pickup.cityLng
            },
            // "destinationLocation": {
            //     "latitude": ride.dest.cityLat,
            //     "longitude": ride.dest.cityLng
            // },
            "ride": {
                // "measureLookupId": 100016004,
                // "subCategoryId": subCategoryId,
                "rideStartDate": ride.startDate,
                "rideEndDate": ride.endDate
            }
        }

        // let data = {
        //     "destinationLocation":{
        //        "latitude":17.385044,
        //        "longitude":78.486671
        //     },
        //     "pickupLocation":{
        //        "latitude":12.971599,
        //        "longitude":77.59456
        //     },
        //     "ride":{
        //         "subCategoryId": 100160036,
        //        "measureLookupId": 100016004,
        //        "rideStartDate":"16-10-2018 19:00:00",
        //        "rideEndDate":"17-10-2018 19:00:00"
        //     }      
        //  }
        console.log(data);
        logMessage('fetchVehicleDetails Self Drive Call Started');
        axios.post(url, data)
            .then(response => {
                console.log(response);
                dispatch(selfDriveLoadingFalse());
                if (response.data.statusCD === 200 && response.data.responseData.length > 0) {
                    
             logMessage('fetchVehicleDetails Self Drive Call Succeeded');
                    // let groupByVendor = [];

                    //to be removed
                    // response.data.responseData.push(data1);
                    // response.data.responseData.push(data2);
                    // response.data.responseData.push(data3);
                    //to be removed
                    
                    //Latest Logic To be Removed
                    //-----------------------------------------//
                    // let groupByVendor = _.toArray(_.groupBy(response.data.responseData, resData => resData.account_id));
                    // let finalGroup = [];
                    // for(let i=0; i<groupByVendor.length; i++){
                    //     finalGroup.push({
                    //         account_id: groupByVendor[i][0].account_id,
                    //         image_url: groupByVendor[i][0].image_url,
                    //         withFuel: [],
                    //         withoutFuel: []
                    //     })
                    //     for(let j=0; j<groupByVendor[i].length; j++){
                    //         if(groupByVendor[i][j].measure_cd.split(' ')[1] === 'without'){
                    //             finalGroup[i].withoutFuel.push(groupByVendor[i][j]);
                    //         }
                    //         else{
                    //             finalGroup[i].withFuel.push(groupByVendor[i][j]);
                    //         }
                    //     }
                    // }


                    //----------------------------------------//
                    //to be removed
                    // mainDummy[1].withFuel = [];
                    // finalGroup[1].withoutFuel = [];
                    
                    
                    let groupBySubCategory = _.toArray(_.groupBy(response.data.responseData, resData => resData.sub_category_id));
                    //Removing the last array which contains the cars with no sub_category_id
                    // console.log(groupBySubCategory);
                    groupBySubCategory.pop();

                    // console.log(groupBySubCategory);

                    let groupedVendors=[];
                    //Grouping the cars as per vendors and pushing into the groupedVendors Array
                    groupBySubCategory.map((obj)=>{
                        groupedVendors.push(_.toArray(_.groupBy(obj,objdata=>objdata.account_id)));
                    });


                    let finalVendorsArray=[];
                    //.mapping the groupedVendors array to go inside 3 levels in order to get the vendors and their data and storing everything in finalVendorsArray
                    groupedVendors.map((vendorArray)=>{
                        let selfDriveObject={carCard:{},vendors:[]};
                        vendorArray.map((specificVendorArray)=>{
                                let vendorObject={withFuel:[],withoutFuel:[]};
                                specificVendorArray.map((specificVendorObject)=>{
                                    if(specificVendorObject.hasOwnProperty('sub_category_id')){
                                        if(specificVendorObject.measure_cd.split(' ')[1] === 'without')
                                        {
                                            vendorObject.withoutFuel.push(specificVendorObject);
                                        }
                                        else
                                        {
                                            vendorObject.withFuel.push(specificVendorObject);
                                        }
                                    }    
                                });
                            selfDriveObject.vendors.push(vendorObject);    
                        });
                        selfDriveObject.carCard=vendorArray[0][0];
                        finalVendorsArray.push(selfDriveObject);    
                    });
                    
                    // console.log(finalVendorsArray);
                    

                    dispatch(updateSelfDriveCars(finalVendorsArray));
                    dispatch(selfDriveAvailableTrue()); 
                }
                else {
                    logMessage('fetchVehicleDetails Self Drive Call Failed');
                    dispatch(selfDriveLoadingFalse());
                    dispatch(updateSelfDriveCars([]));
                    dispatch(selfDriveAvailableFalse());
                }
            })
            .catch(err => {
                logMessage('fetchVehicleDetails Self Drive Call Failed');
                console.log(err);
                dispatch(selfDriveLoadingFalse());
                dispatch(updateSelfDriveCars([]));
                dispatch(selfDriveAvailableFalse());
            })
    }
}


export function fetchMyChoizePricing(accountId, data, pickLocation, dropLocation, additionalService, index ,success, error){
    return function(dispatch, getState){
        let url = urls.myChoizePricing;
        Object.assign(data, { PickRegionKey: pickLocation, DropRegionKey: dropLocation, AdditionalService: additionalService })
        let input = {
            "accountId": accountId,
            "data": data
        }
        axios.post(url, input)
        .then(response => {
            console.log(response);
            if(response.data.statusCD === 200){
                logMessage('fetchMyChoizePricing Call Succeeded');
                dispatch(logTransaction('fetchMyChoizePricing Call Succeeded',input));
                success(response.data.responseData,index);
            }
            else{
                logMessage('fetchMyChoizePricing Call Failed');
                dispatch(logTransaction('fetchMyChoizePricing Call Failed',input));
                error(index);
            }
        })
        .catch( 
            err=> {
                logMessage('fetchMyChoizePricing Call Failed');
                dispatch(logTransaction('fetchMyChoizePricing Call Failed',input));
                console.log(err)
            }
            )
    }
}

export function fetchAirportVehicleDetails(token) {
    return function (dispatch, getState) {
        
        dispatch(couponActions.fetchCoupons());
        // dispatch(airportCarsLoadingTrue());
        let ride = getState().ride;
        let url = urls.vehiclesPricing;
        // airport change
        // let isAirportPickup = ride.pickup.loc.includes("Airport") || ride.pickup.loc.includes("airport") ? true : false;
        // let isAirportDrop = ride.dest.loc.includes("Airport") || ride.dest.loc.includes("airport") ? true : false;
        // let isAirport=false;
        let isAirportPickup = ride.airportType === 'Airport Pickup' ? true : false
        let isAirportDrop = ride.airportType === 'Airport Dropoff' ? true: false
        // let isAirportPickup= ride.rentalType === 'airPick' ? true : false;
        // let isAirportDrop= ride.rentalType === 'airDrop' ? true : false;
        let stops  = [];
        for(let i=0;i<ride.stops.length;i++){
            stops.push(ride.stops[i]);
        }
        // let stops = ride.stops
        if(ride.measureLookupId === "100010001"){
            stops = stops.splice(-1,1);
        }

        let data = {
            "pickupLocation": {
                "latitude":  ride.pickup.cityLat,
                "longitude": ride.pickup.cityLng
            },
            "destinationLocation": {
                // "latitude":  isAirportDrop ? ride.dest.locLat  :ride.dest.cityLat,
                // "longitude": isAirportDrop ? ride.dest.locLng  :ride.dest.cityLng
                "latitude":  ride.pickup.cityLat,
                "longitude": ride.pickup.cityLng
            },
            "stops": stops,
            "ride": {
                "measureLookupId": isAirportPickup ? 100010014 : (isAirportDrop ? 100010015 : 100007120),
                "rideStartDate": ride.startDate + " " + ride.startTime,
                // "rideEndDate": ride.endDate + " " + ride.endTime,
                // "distance": undefined
                // "distance": ride.measureLookupId === "100010001" ? parseInt(ride.distance / 2) : ride.distance, 
                "distance":null      
            }
        }
        //oneway time is 11:59 pm 
        // if(data.ride.measureLookupId === '100010011'){
        //     data.ride.rideEndDate = ride.startDate + ' 23:59:00'
        // }
        // console.log(data);
        let config = {};
        if (token !== '') {
            config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        }
        if(isAirportDrop || isAirportPickup)
        {
            logMessage('fetchVehicleDetails Airport Call Started');
            axios.post(url, data, config)
                .then(response => {
                    console.log(response);
                    dispatch(airportCarsLoadingFalse());
                    if (response.data.statusCD === 200 && response.data.responseData.length > 0) {
                        
                        logMessage('fetchVehicleDetails Airport Call Succeeded');
                        dispatch(logTransaction('fetchVehicleDetails Airport Call Succeeded',data));
                        let cars=response.data.responseData;
                        let updatedCars=[];
                        let coupons=_.orderBy(getState().coupons.all, [coupon => coupon.discountValue], ['desc']);

                        if(coupons.length<=0){
                            // dispatch(updateAirportCars(response.data.responseData));
                            cars.forEach(car => {
                                let vendors=[];
                                car.vendors.forEach(vendor => {
                                    let newObj=Object.assign({},vendor,{ discountedValue:vendor.ride_amt,coupon:null,couponValue:0});                                        
                                    vendors.push(newObj);
                                })
                                let obj=Object.assign({},car,{vendors});
                                updatedCars.push(obj);
                            })
                            dispatch(updateAirportCars(updatedCars));
                        }

                        else{
                            cars.forEach(car => {
                                let vendors=[];
                                car.vendors.forEach(vendor => {
                                    let couponApplied=false;
                                    for(let i=0;i<coupons.length && !couponApplied;i++){
                                            //apply to specific vendor
                                        if (coupons[i].hasOwnProperty('measureLookups')){
                                            if(coupons[i].hasOwnProperty('accountId')){
                                                if((coupons[i].accountId===vendor.account_id ) && (vendor.ride_amt>=coupons[i].minAmount)  && coupons[i].measureLookups.includes(vendor.measure_lookup_id)){
                                                let newObj=Object.assign({},vendor,{ discountedValue:vendor.ride_amt-coupons[i].discountValue,coupon:coupons[i].name,couponValue:coupons[i].discountValue })
                                                vendors.push(newObj);
                                                couponApplied=true;
                                                // console.log(newObj);
                                                }
                                            }
                                            //Apply to all Vendors
                                            else{
                                                if((vendor.ride_amt>=coupons[i].minAmount)  && coupons[i].measureLookups.includes(vendor.measure_lookup_id)){
                                                    let newObj=Object.assign({},vendor,{ discountedValue:vendor.ride_amt-coupons[i].discountValue,coupon:coupons[i].name,couponValue:coupons[i].discountValue});
                                                    vendors.push(newObj); 
                                                    couponApplied=true;
                                                }
                                            }
                                        }
                                    }
                                    if(!couponApplied){
                                        let newObj=Object.assign({},vendor,{ discountedValue:vendor.ride_amt,coupon:null,couponValue:0});                                        
                                        vendors.push(newObj);
                                    }               
                                });
                                let obj=Object.assign({},car,{vendors});
                                updatedCars.push(obj);
                                // console.log(obj);
                            });
                            // console.log(updatedCars);
                            dispatch(updateAirportCars(updatedCars));
                        }
                        
                        dispatch(airportCarsAvailableTrue());
                    }
                    else {
                        logMessage('fetchVehicleDetails Airport Call Failed');
                        dispatch(logTransaction('fetchVehicleDetails Airport Call Failed',data));
                        dispatch(updateAirportCars([]));
                        dispatch(airportCarsAvailableFalse());
                    }
                })
                .catch(err => {
                    
                    console.log(err);
                    logMessage('fetchVehicleDetails Airport Call Failed');
                    dispatch(logTransaction('fetchVehicleDetails Airport Call Failed',data));
                    dispatch(airportCarsLoadingFalse());
                    dispatch(updateAirportCars([]));
                    dispatch(airportCarsAvailableFalse());
                })
        }
        else{
            dispatch(updateAirportCars([]));
            dispatch(airportCarsLoadingFalse());            
            dispatch(airportCarsAvailableFalse());
        }
    }
   
}

export function fetchRentalVehicleDetails(token) {
    return function (dispatch, getState) {
        
        dispatch(couponActions.fetchCoupons());
        // dispatch(rentalCarsLoadingTrue());
        let ride = getState().ride;
        let url = urls.vehiclesPricing;
        let measureLookupId;
        if(ride.rentalType === 4){
            measureLookupId = 100010009
            // 2 hrs
        }
        else if(ride.rentalType === 5){
            measureLookupId = 100010006
            // 4 hrs
        }
        else if(ride.rentalType === 6){
            measureLookupId = 100010007
            // 8hours
        }
        else if(ride.rentalType === 7) {
            measureLookupId = 100010008
            // 12hours
        }
        else{
            measureLookupId = null
        }
        let data = {
            "pickupLocation": {
                "latitude":  ride.pickup.cityLat,
                "longitude": ride.pickup.cityLng
            },
            "destinationLocation": {
                "latitude":  ride.pickup.locLat ,
                "longitude": ride.pickup.locLng 
            },
            "stops": ride.stops,
            "ride": {
                "measureLookupId": measureLookupId,
                "rideStartDate": ride.startDate + " " + ride.startTime,
                // "rideEndDate": ride.endDate + " " + ride.endTime,
                // "distance": ride.distance          
            }
        }
        let config = {};
        if (token !== '') {
            config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        }
        // console.log(data);
        if(measureLookupId===null){
            dispatch(rentalCarsLoadingFalse());
            dispatch(updateRentalCars([]));
            dispatch(rentalCarsAvailableFalse());
        }
        else{
            logMessage('fetchVehicleDetails Rental Call Started');
            axios.post(url, data, config)
                .then(response => {
                    console.log(response);
                    dispatch(rentalCarsLoadingFalse());
                    if (response.data.statusCD === 200 && response.data.responseData.length > 0) {
                        
                        logMessage('fetchVehicleDetails Rental Call Succeeded');
                        dispatch(logTransaction('fetchVehicleDetails Rental Call Succeeded',data));
                        let cars=response.data.responseData;
                        let updatedCars=[];
                        let coupons=_.orderBy(getState().coupons.all, [coupon => coupon.discountValue], ['desc']);

                        if(coupons.length<=0){
                            // dispatch(updateRentalCars(response.data.responseData));
                            cars.forEach(car => {
                                let vendors=[];
                                car.vendors.forEach(vendor => {
                                    let newObj=Object.assign({},vendor,{ discountedValue:vendor.ride_amt,coupon:null,couponValue:0});                                        
                                    vendors.push(newObj);
                                })
                                let obj=Object.assign({},car,{vendors});
                                updatedCars.push(obj);
                            })
                            dispatch(updateRentalCars(updatedCars));
                        }

                        else{
                            cars.forEach(car => {
                                let vendors=[];
                                car.vendors.forEach(vendor => {
                                    let couponApplied=false;
                                    for(let i=0;i<coupons.length && !couponApplied;i++){
                                            //apply to specific vendor
                                        if (coupons[i].hasOwnProperty('measureLookups')){
                                            if(coupons[i].hasOwnProperty('accountId')){
                                                if((coupons[i].accountId===vendor.account_id ) && (vendor.ride_amt>=coupons[i].minAmount)  && coupons[i].measureLookups.includes(vendor.measure_lookup_id)){
                                                let newObj=Object.assign({},vendor,{ discountedValue:vendor.ride_amt-coupons[i].discountValue,coupon:coupons[i].name,couponValue:coupons[i].discountValue })
                                                vendors.push(newObj);
                                                couponApplied=true;
                                                // console.log(newObj);
                                                }
                                            }
                                            //Apply to all Vendors
                                            else{
                                                if((vendor.ride_amt>=coupons[i].minAmount)  && coupons[i].measureLookups.includes(vendor.measure_lookup_id)){
                                                    let newObj=Object.assign({},vendor,{ discountedValue:vendor.ride_amt-coupons[i].discountValue,coupon:coupons[i].name,couponValue:coupons[i].discountValue});
                                                    vendors.push(newObj); 
                                                    couponApplied=true;
                                                }
                                            }
                                        }
                                    }
                                    if(!couponApplied){
                                        let newObj=Object.assign({},vendor,{ discountedValue:vendor.ride_amt,coupon:null,couponValue:0});                                        
                                        vendors.push(newObj);
                                    }               
                                });
                                let obj=Object.assign({},car,{vendors});
                                updatedCars.push(obj);
                                // console.log(obj);
                            });
                            // console.log(updatedCars);
                            dispatch(updateRentalCars(updatedCars));
                        }
                        
                        dispatch(rentalCarsAvailableTrue());
                    }
                    else {
                        logMessage('fetchVehicleDetails Rental Call Failed');
                        dispatch(logTransaction('fetchVehicleDetails Rental Call Failed',data));
                        dispatch(updateRentalCars([]));
                        dispatch(rentalCarsAvailableFalse());
                    }
                })
                .catch(err => {
                    
                    console.log(err);
                    logMessage('fetchVehicleDetails Rental Call Failed');
                    dispatch(logTransaction('fetchVehicleDetails Rental Call Failed',data));
                    dispatch(rentalCarsLoadingFalse());
                    dispatch(updateRentalCars([]));
                    dispatch(rentalCarsAvailableFalse());
                })
            }
        }
}

export function fetchRating(vendorId,error){
    return function (dispatch, getState) {
        let url = urls.fetchOrUpdateRating;
        let data = {
            "isUpdate": false,
            "vendorId":vendorId,
        }
        console.log(data);
        axios.post(url, data)
            .then(response => {
                
                if (response.data.statusCD === 200) {
                    logMessage('fetchOrUpdateRating Call Succeeded');
                    dispatch(logTransaction('fetchOrUpdateRating Call Succeeded',data));
                    dispatch(showRating(response.data.responseData));
                    // dispatch(updateProfile(response.data.responseData));
                }
            })
            .catch(err => {;console.log(err);error(err)})
    }
}

export function fetchMyChoizeLocationAndActivityList(data,success,error){
    return function (dispatch, getState) {
        let url = urls.fetchMyChoizeLocationAndActivityList;
        console.log(data);
        axios.post(url, data)
            .then(response => {
                
                if (response.data.statusCD === 200) {
                    logMessage('fetchMyChoizeLocationAndActivityList Call Succeeded');
                    dispatch(logTransaction('fetchMyChoizeLocationAndActivityList Call Succeeded',data));
                    console.log(response);
                    success(response.data.responseData);
                }
                else{
                    logMessage('fetchMyChoizeLocationAndActivityList Call Failed');
                    dispatch(logTransaction('fetchMyChoizeLocationAndActivityList Call Failed',data));
                    console.log(response);
                }
            })
            .catch(err => {
                ;
                logMessage('fetchMyChoizeLocationAndActivityList Call Failed');
                dispatch(logTransaction('fetchMyChoizeLocationAndActivityList Call Failed', data));
                console.log(err);error(err)
            })
    }
}

export function generateTokenForVehicles() {
    return function (dispatch, getState) {
        let ride = getState().ride;
        let url = generateToken;
        let config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                // 'Authorization': urlType === 'Production' ? "Basic enlwcHlzX3JldGFpbF9jbGllbnQ6enlwcHlzX3JldGFpbF9zZWNyZXQ=" : "Basic enlwcHlzX3JldGFpbF9jbGllbnQ6enlwcHlzX3JldGFpbF9zZWNyZXQ="
                'Authorization': "Basic enlwcHlzX3JldGFpbF9jbGllbnQ6enlwcHlzX3JldGFpbF9zZWNyZXQ="
            },
            // auth: {
            //     username: 'zyppys_retail_client',
            //     password: 'zyppys_retail_secret'
            // }
        }

        let data;

        // Test Credentials
        if (urlType === 'Test') {
            // data = {
            //     grant_type: "password",
            //     username: "javainuse-user",
            //     password: "javainuse-pass"
            // }
            data = {
                grant_type: "password",
                username: "zyppys_retail_app_user",
                password: "zyppys_retail_app_pwd"
            }
        }

        // Production Credentials
        if (urlType === 'Production') {
            data = {
                grant_type: "password",
                username: "zyppys_retail_app_user",
                password: "zyppys_retail_app_pwd"
            }
        }

        console.log("Generate Token URL ", url, "\nGenerate Token Data ", data, "\nGenerate Token Config", config)
        axios.post(url, queryString.stringify(data), config)
            .then(response => {
                console.log("Generate Token Response", response)
                if (ride.rentalType === 1) {
                    dispatch(chauffeurCarsLoadingTrue());
                    dispatch(fetchChauffeurVehicleDetails(response.data.access_token));
                }
                else if (ride.rentalType === 2) {
                    dispatch(selfDriveLoadingTrue());
                    dispatch(fetchSelfDriveVehicleDetails(response.data.access_token));
                }
                else if (ride.rentalType === 3) {
                    dispatch(airportCarsLoadingTrue());
                    dispatch(fetchAirportVehicleDetails(response.data.access_token));
                }
                else if (ride.rentalType > 3) {
                    dispatch(rentalCarsLoadingTrue());
                    dispatch(fetchRentalVehicleDetails(response.data.access_token));
                }
            })
            .catch(error => {
                console.log("Generate Token error", error)
                // alert("Looks like there is a network issue.")
                // if (ride.rentalType === 1) {
                //     dispatch(chauffeurCarsLoadingTrue());
                //     dispatch(fetchChauffeurVehicleDetails(''));
                // }
                // else if (ride.rentalType === 2) {
                //     dispatch(selfDriveLoadingTrue());
                //     dispatch(fetchSelfDriveVehicleDetails(''));
                // }
                // else if (ride.rentalType === 3) {
                //     dispatch(airportCarsLoadingTrue());
                //     dispatch(fetchAirportVehicleDetails(''));
                // }
                // else if (ride.rentalType > 3) {
                //     dispatch(rentalCarsLoadingTrue());
                //     dispatch(fetchRentalVehicleDetails(''));
                // }
            })
    }
}