import axios from 'axios';
import { urls as urls } from '../api/urls';

export function updateCoupons(data) {
    return {
        type: 'UPDATE_COUPONS',
        data: data
    }
}

export function updateSelectedCoupon(data) {
    return {
        type: 'UPDATE_SELECTED_COUPON',
        data
    }
}

export function flushCouponState() {
    return {
        type: 'FLUSH_COUPON_STATE'
    }
}

export function couponsAvailableFalse() {
    return {
        type: 'COUPONS_AVAILABLE_FALSE'
    }
}

export function fetchCoupons(userId) {
    return function (dispatch, getState) {
        let url = urls.coupons;
        let data = {
            "customerId": userId ? userId : null,
            "isPortal": true
        }
        axios.post(url, data)
            .then(response => {
                if (response.data.statusCD === 200) {
                    let updatedData = [];
                    response.data.responseData.forEach(data => {
                        if (data.isActive === true) {
                            updatedData.push(data);
                        }
                    });
                    dispatch(updateCoupons(updatedData));
                }
                else {
                    dispatch(couponsAvailableFalse());
                }
            })
    }
}
