import { urls as urls } from '../api/urls.js';
import axios from 'axios';
import _ from 'lodash';

export function updatePickupLocation(data) {
  return {
    type: 'UPDATE_PICKUP_LOC',
    data
  }
}


export function updatePickupCity(data) {
  return {
    type: 'UPDATE_PICKUP_CITY',
    data
  }
}

export function updateDestLocation(data) {
  return {
    type: 'UPDATE_DEST_LOC',
    data
  }
}

export function updateDestCity(data) {
  return {
    type: 'UPDATE_DEST_CITY',
    data
  }
}


export function addStop(data) {
  return {
    type: 'ADD_STOP',
    data
  }
}

export function updateCityOfStop(data, stopId) {
  return {
    type: 'UPDATE_CITY_OF_STOP',
    data,
    stopId
  }
}

export function updateLeadTime(data) {
  return {
    type: 'UPDATE_LEAD_TIME',
    data
  }
}

export function updateTripType(data) {
  if (data === "Round Trip") {
    return {
      type: 'UPDATE_TRIP_TYPE',
      id: '100010001'
    }
  }
  else if (data === "One Way") {
    return {
      type: 'UPDATE_TRIP_TYPE',
      id: '100010011'
    }
  }
}


export function removeLastStop() {
  return { type: 'REMOVE_LAST_STOP' }
}

export function setSelfDrivenToTrue() {
  return {
    type: 'SELF_DRIVEN_TRUE',
  }
}

export function setChauffeurDrivenToTrue() {
  return {
    type: 'CHAUFFEUR_DRIVEN_TRUE',
  }
}

export function setAirportToTrue() {
  return {
    type: 'AIRPORT_TRUE',
  }
}

export function setRentalToTrue() {
  return {
    type: 'RENTAL_TRUE',
  }
}

export function updateStartDate(data) {
  return {
    type: 'UPDATE_START_DATE',
    data
  }
}

export function updateEndDate(data) {
  return {
    type: 'UPDATE_END_DATE',
    data
  }
}

export function updateStartTime(data) {
  return {
    type: 'UPDATE_START_TIME',
    data
  }
}

export function updateEndTime(data) {
  return {
    type: 'UPDATE_END_TIME',
    data
  }
}

export function addStopAtIndex(index, data) {
  
  return { type: 'ADD_STOP_AT_INDEX', index, data }
}

export function pushStopAtIndex(index, data) {
  
  return { type: 'PUSH_STOP_AT_INDEX', index, data }
}


export function editStop(id, data) {
  
  return { type: 'EDIT_STOP', id, data }
}

export function updateRideDistance(data) {
  return {
    type: 'UPDATE_RIDE_DISTANCE',
    data
  }
}

export function updateRideDuration(data) {
  return {
    type: 'UPDATE_RIDE_DURATION',
    data
  }
}


export function addFirstStop(data) {
  return {
    type: 'ADD_FIRST_STOP',
    data
  }
}

export function updateRideStartDate(data) {
  
  return {
    type: 'UPDATE_START_DATE',
    data
  }
}

export function updateRideEndDate(data) {
  return {
    type: 'UPDATE_END_DATE',
    data
  }
}

export function updateRentalType(data) {
  return {
    type: 'UPDATE_RENTAL_TYPE',
    data
  }
}


export function updateHourlyPackage(data) {
  return {
    type: 'UPDATE_HOURLY_PACKAGE',
    data
  }
}


export function flushRideState() {
  return {
    type: 'FLUSH_RIDE_STATE'
  }
}

export function nearbyTrue() {
  return {
    type: 'NEARBY_CITY_TRUE',
  }
}

export function nearbyFalse() {
  return {
    type: 'NEARBY_CITY_FALSE',
  }
}

export function updateCurrentDateTime(data) {
  return {
    type: 'UPDATE_CURRENT_DATE_TIME',
    data
  }
}

export function updateStaticCities(data) {
  return {
    type: 'UPDATE_STATIC_CITIES',
    data
  }
}

export function updateAirportType(data) {
  return {
    type: 'UPDATE_AIRPORT_TYPE',
    data
  }
}


export function fetchLocationByLatLong(city, city1) {
  return function (dispatch, getState) {
    
    if (city === 'Secunderabad') {
      city = 'Hyderabad';
    }
    if (city === "Bangalore" || city1 === "Bengaluru Rural" || city1 === "Bengaluru Urban" || city1 === "Bangalore Rural" || city1 === "Bangalore Urban") {
      city = "Bengaluru";
    }
    if (city1 === 'Secunderabad') {
      city1 = 'Hyderabad';
    }
    if (city1 === "Bangalore" || city1 === "Bengaluru Rural" || city1 === "Bengaluru Urban" || city1 === "Bangalore Rural" || city1 === "Bangalore Urban") {
      city1 = "Bengaluru";
    }
    let self = this;
    let url = urls.locationByLatLongUrl;
    let input = { name: city };
    let input1 = { name: city1 };
    axios.post(url, input)
      .then(function (response) {
        console.log(response);
        if (response.data.statusCD === 200) {
          
          let result = response.data.responseData;
          console.log(result);
          dispatch(nearbyFalse());
          dispatch(updatePickupCity({ city: city, cityLat: result.latitude, cityLng: result.longitude, cityLeadTime: result.leadTime }));
          dispatch(updateCityOfStop({ city: city, cityLat: result.latitude, cityLng: result.longitude, cityLeadTime: result.leadTime }, 0));
          if (getState().ride.rentalType === 1) {
            dispatch(updateLeadTime(String(result.leadTime) + '.25'));
          }
          else {
            dispatch(updateLeadTime(result.leadTime));
          }
        }
        if (response.data.statusCD === 404 && input1 !== '') {
          axios.post(url, input1)
            .then(function (response1) {
              if (response1.data.statusCD === 200) {
                
                let result1 = response1.data.responseData;
                dispatch(nearbyTrue());
                dispatch(updatePickupCity({ city: city1, cityLat: result1.latitude, cityLng: result1.longitude, cityLeadTime: result1.leadTime }));
                dispatch(updateCityOfStop({ city: city1, cityLat: result1.latitude, cityLng: result1.longitude, cityLeadTime: result1.leadTime }, 0));
                if (getState().ride.rentalType === 1) {
                  dispatch(updateLeadTime(String(result1.leadTime) + '.25'));
                }
                else {
                  dispatch(updateLeadTime(result1.leadTime));
                }
              }
              if (response1.data.statusCD === 404) {

                dispatch(updatePickupCity({ city: city1, cityLat: '', cityLng: '', cityLeadTime: '' }));
              }
            })
            .catch(function (error) {
              dispatch(updatePickupCity({ city: city, cityLat: '', cityLng: '', cityLeadTime: '' }));
            });
        }
      })
      .catch(function (error) {
        dispatch(updatePickupCity({ city: city, cityLat: '', cityLng: '', cityLeadTime: '' }));
      });
  }
}

export function fetchStaticCities() {
  return function (dispatch, getState) {
    let url = urls.fetchStaticCities;
    let data = {};
    axios.post(url, data)
      .then(response => {
        // console.log(response);
        if (response.data.statusCD === 200) {
          let fetchData = _.clone(response.data.responseData);
          let cities = [];
          let marketingData = [];
          fetchData.map(data => {
            if (data.hasOwnProperty('measure_lookups') || data.hasOwnProperty('measureLookups')) {
              cities.push(data);
            }
            else if (data.hasOwnProperty('lookup_category_id')) {
              marketingData.push(data);
            }
          })
          cities.map((item) => {
            let label = item.name;
            let value = { latitude: item.latitude, longitude: item.longitude };
            item.label = label;
            item.value = value;
          })
          if (cities.length > 0) {
            let roundTrip = cities.filter(c => c.hasOwnProperty('measureLookups') ? c.measureLookups.includes('100010001') : c.measure_lookups.includes('100010001'));
            let hr4 = cities.filter(c => c.hasOwnProperty('measureLookups') ? c.measureLookups.includes('100010006') : c.measure_lookups.includes('100010006'));
            let hr8 = cities.filter(c => c.hasOwnProperty('measureLookups') ? c.measureLookups.includes('100010007') : c.measure_lookups.includes('100010007'));
            let hr12 = cities.filter(c => c.hasOwnProperty('measureLookups') ? c.measureLookups.includes('100010008') : c.measure_lookups.includes('100010008'));
            let oneWay = cities.filter(c => c.hasOwnProperty('measureLookups') ? c.measureLookups.includes('100010011') : c.measure_lookups.includes('100010011'));
            let airportPickup = cities.filter(c => c.hasOwnProperty('measureLookups') ? c.measureLookups.includes('100010014') : c.measure_lookups.includes('100010014'));
            let airportDrop = cities.filter(c => c.hasOwnProperty('measureLookups') ? c.measureLookups.includes('100010015') : c.measure_lookups.includes('100010015'));
            let selfDrive = cities.filter(c => c.hasOwnProperty('measureLookups') ? c.measureLookups.includes('100016000') : c.measure_lookups.includes('100016000'));
            // console.log("ROUNDTRIP",roundTrip,"HR4",hr4,"HR8",hr8,"HR12",hr12,"ONEWAY",oneWay,"AIRPORT PICKUP",airportPickup,"AIRPORT DROP",airportDrop,"SELF DRIVE",selfDrive);
            let rentalCities = {
              roundTrip: roundTrip,
              hr4: _.orderBy(hr4, ['name'], ['asc']),
              hr8: _.orderBy(hr8, ['name'], ['asc']),
              hr12: _.orderBy(hr12, ['name'], ['asc']),
              oneWay: _.orderBy(oneWay, ['name'], ['asc']),
              airportPickup: _.orderBy(airportPickup, ['name'], ['asc']),
              airportDrop: _.orderBy(airportDrop, ['name'], ['asc']),
              selfDrive: _.orderBy(selfDrive, ['name'], ['asc'])
            }
            dispatch(updateStaticCities(rentalCities));
          }
          else {
            let rentalCities = {
              roundTrip: [],
              hr4: [],
              hr8: [],
              hr12: [],
              oneWay: [],
              airportPickup: [],
              airportDrop: [],
              selfDrive: []
            }
            dispatch(updateStaticCities(rentalCities));
          }
        }
      })
  }
}

export function fetchZoomcarLocations(data, success, error) {
  return function (dispatch, getState) {
    let url = urls.fetchZoomcarLocations;
    let inputData = {
      "accountId": data.accountId,
      "carGroupId": data.carGroupId,
      "fromDate": data.fromDate,
      "toDate": data.toDate,
      "city": data.city
    };
    console.log("Input Data", inputData)
    axios.post(url, inputData)
      .then(response => {
        console.log("Fetch Zoomcar Locations Response", response);
        if (response.data.statusCD === 200) {
          let locations = response.data.responseData;
          success(locations);
        }
        else {
          error(response.data.statusMSG)
        }
      })
      .catch(err => {
        console.log("Fetch Zoomcar Locations Error", err);
        error("Looks like there is a network error. Please try again after some time.");
      })
  }
}