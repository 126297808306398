import { urls as urls } from '../api/urls';
import axios from 'axios';
import { UpdatePlaceHolderData } from './placeHolderAction';


export function updateSelectedCar(data) {
    return {
        type: 'UPDATE_SELECTED_CAR',
        data
    }
}

export function updateSelectedVendor(data) {
    return {
        type: 'UPDATE_SELECTED_VENDOR',
        data
    }
}

export function updatePaymentId(data) {
    return {
        type: 'UPDATE_PAYMENT_ID',
        data
    }
}

export function updateBidId(data) {
    return {
        type: 'UPDATE_BID_ID',
        data
    }
}

export function bidSubmitted() {
    return {
        type: 'BID_SUBMITTED',
    }
}

export function flushOrderState() {
    return {
        type: 'FLUSH_ORDER_STATE'
    }
}

export function rideCancelled() {
    return {
        type: 'CANCEL_RIDE'
    }
}

export function updateScratchAmount(data) {
    return {
        type: 'UPDATE_SCRATCH_AMOUNT',
        data
    }
}

export function updateAdvanceAmount(data) {
    return {
        type: 'UPDATE_ADVANCE_AMOUNT',
        data
    }
}


export function fullPaid() {
    return {
        type: 'FULL_PAID',
    }
}


export function updateRemarks(data) {
    return {
        type: 'UPDATE_REMARKS',
        data
    }
}

export function updateIP(data) {
    return {
        type: 'UPDATE_IP',
        data
    }
}

export function submitBid(MyChoizeAdddress, selectedVendor1, myChoizeBlockedVehicle, data, success, error) {
    return function (dispatch, getState) {
        
        let url = urls.submitUrl;
        let submitData;
        let ride = getState().ride;
        let stops = ride.stops;
        let selectedCar = getState().order.selectedCar;
        let selectedVendor = getState().order.selectedVendor;
        let user = getState().user;
        let couponCode = selectedVendor.coupon;
        let isChauffeurDriven = getState().ride.rentalType === 1 ? true : false;
        let isSelfDriven = getState().ride.rentalType === 2 ? true : false;
        let isAirport = getState().ride.rentalType === 3 ? true : false;
        let isRental = getState().ride.rentalType >= 4 ? true : false;
        let myChoize = getState().myChoize;
        let paymentId = getState().order.paymentId;
        

        submitData = {
            "bid": {
                "bidAmount": selectedVendor.base_fare
            },
            "pricingRequestBean": {
                "pickupLocation": {
                    "latitude": ride.pickup.cityLat,
                    "longitude": ride.pickup.cityLng
                },
                // "pickupLocation": {
                //     "latitude": 17.385044,
                //     "longitude": 78.486671
                // },
                "destinationLocation": {
                    "latitude": ride.dest.cityLat,
                    "longitude": ride.dest.cityLng
                },
                "user": {
                    "name": user.name,
                    // "countryCode": this.state.countryCode,
                    "mobileNo": user.phone,
                    "emailId": user.email
                },
                "stops": stops,
                "ride": {
                    "isPortal": true,
                    "pickupCoordinates": ride.pickup.locLat + "," + ride.pickup.locLng,
                    "pickupLocality": ride.pickup.loc,
                    "destinationCoordinates": ride.dest.locLat + "," + ride.dest.locLng,
                    "destinationLocality": ride.dest.loc,
                    "rideStartDate": ride.startDate + " " + ride.startTime,
                    "rideEndDate": ride.endDate + " " + ride.endTime,
                    "distance": ride.distance,
                    "categoryId": selectedCar.category_id,
                    "subCategoryId": selectedCar.sub_category_id,
                    "accountId": selectedVendor.account_id,
                    "couponCode": couponCode,
                    "redeemedPoints": data.redeemedPoints,
                    "transmissionTypeId": selectedVendor.transmission_type_id,
                    "fuelTypeId": selectedVendor.fuel_type_id
                }
            }
        }

        if (isChauffeurDriven && ride.measureLookupId === '100010011' && selectedCar.category !== "SELF DRIVE") {
            submitData.pricingRequestBean.ride.measureLookupId = ride.measureLookupId;
            submitData.pricingRequestBean.ride.rideEndDate = ride.startDate + ' 23:59:00'
            submitData.pricingRequestBean.ride.paymentId = paymentId

        }
        else if (isChauffeurDriven) {
            submitData.pricingRequestBean.ride.measureLookupId = ride.measureLookupId;
            submitData.pricingRequestBean.ride.paymentId = paymentId
        }
        else if (isAirport) {
            // let isAirportPickup = ride.pickup.loc.includes("Airport") || ride.pickup.loc.includes("airport") ? true : false;
            // let isAirportDrop = ride.dest.loc.includes("Airport") || ride.dest.loc.includes("airport") ? true : false;
            let isAirportPickup = ride.airportType === 'Airport Pickup' ? true : false;
            let isAirportDrop = ride.airportType === 'Airport Dropoff' ? true : false;
            submitData.pricingRequestBean.ride.measureLookupId = isAirportPickup ? 100010014 : (isAirportDrop ? 100010015 : 1111188111);
            submitData.pricingRequestBean.ride.paymentId = paymentId;
            // submitData.pricingRequestBean.destinationLocation.latitude = isAirportDrop ? ride.dest.locLat : ride.dest.cityLat;
            // submitData.pricingRequestBean.destinationLocation.longitude = isAirportDrop ? ride.dest.locLng : ride.dest.cityLng;
            submitData.pricingRequestBean.ride.pickupCoordinates = myChoize.pickup.locLat + "," + myChoize.pickup.locLng;
            submitData.pricingRequestBean.ride.pickupLocality = myChoize.pickup.hasOwnProperty('loc') ? myChoize.pickup.loc : MyChoizeAdddress.pick;
            submitData.pricingRequestBean.ride.destinationCoordinates = myChoize.dest.locLat + "," + myChoize.dest.locLng;
            submitData.pricingRequestBean.ride.destinationLocality = myChoize.dest.hasOwnProperty('loc') ? myChoize.dest.loc : MyChoizeAdddress.drop;  
            submitData.pricingRequestBean.stops = myChoize.stops;    
        }
        else if (isRental) {
            let measureLookup;
            // submitData.pricingRequestBean.pickupLocation.latitude=ride.pickup.locLat;
            // submitData.pricingRequestBean.pickupLocation.longitude=ride.pickup.locLng;
            submitData.pricingRequestBean.destinationLocation.latitude = ride.dest.locLat;
            submitData.pricingRequestBean.destinationLocation.longitude = ride.dest.locLng;
            delete submitData.pricingRequestBean.ride.distance;
            if (ride.rentalType === 4) {
                measureLookup = 100010009
                // 2 hrs
            }
            else if (ride.rentalType === 5) {
                measureLookup = 100010006
                // 4 hrs
            }
            else if (ride.rentalType === 6) {
                measureLookup = 100010007
                // 8hours
            }
            else if (ride.rentalType === 7) {
                measureLookup = 100010008
                // 12hours
            }
            else {
                measureLookup = null
            }
            submitData.pricingRequestBean.ride.measureLookupId = measureLookup;
            submitData.pricingRequestBean.ride.paymentId = paymentId;
            submitData.pricingRequestBean.ride.pickupCoordinates = ride.pickup.cityLat + "," + ride.pickup.cityLng;
            submitData.pricingRequestBean.ride.pickupLocality = ride.pickup.city;
            // submitData.pricingRequestBean.ride.pickupCoordinates = myChoize.pickup.locLat + "," + myChoize.pickup.locLng;
            // submitData.pricingRequestBean.ride.pickupLocality = myChoize.pickup.hasOwnProperty('loc') ? myChoize.pickup.loc : MyChoizeAdddress.pick;
            // let stops = [];
            // stops.push(myChoize.stops[0]);
            // submitData.pricingRequestBean.stops = stops;
        }
        else {
            submitData.pricingRequestBean.ride.measureLookupId = selectedVendor.measure_lookup_id;
            submitData.pricingRequestBean.ride.paymentId = paymentId;
            submitData.pricingRequestBean.user.licenseNo = user.licenseNo;
            submitData.pricingRequestBean.user.aadharNo = user.aadharNo;
            submitData.pricingRequestBean.user.panNumber = user.panNumber;
            submitData.pricingRequestBean.user.passportNo = user.passportNo;
            submitData.pricingRequestBean.user.licenseFront = user.licenseFront;
            submitData.pricingRequestBean.user.licenseBack = user.licenseBack;
            submitData.pricingRequestBean.user.passportFirstPage = user.passportDetailsPage;
            submitData.pricingRequestBean.user.aadharFront = user.aadharFront;
            submitData.pricingRequestBean.user.aadharBack = user.aadharBack;
            submitData.pricingRequestBean.user.panCard = user.panCard;
            submitData.pricingRequestBean.user.utilityNo = user.utilityNo;
            submitData.pricingRequestBean.user.utilityBill = user.utilityBill;
            submitData.pricingRequestBean.ride.pickupCoordinates = ride.pickup.cityLat + "," + ride.pickup.cityLng;
            submitData.pricingRequestBean.ride.pickupLocality = ride.pickup.city;
            // let stops = [];
            // stops.push(myChoize.stops[0]);
            // submitData.pricingRequestBean.stops = stops;
        }
        if (selectedVendor.account_name === 'MyChoize') {
            console.log(selectedVendor1);
            submitData.pricingRequestBean.ride.pickupCoordinates = myChoize.pickup.locLat + "," + myChoize.pickup.locLng;
            submitData.pricingRequestBean.ride.pickupLocality = myChoize.pickup.hasOwnProperty('loc') ? myChoize.pickup.loc : MyChoizeAdddress.pick;
            submitData.pricingRequestBean.ride.destinationCoordinates = myChoize.dest.locLat + "," + myChoize.dest.locLng;
            submitData.pricingRequestBean.ride.destinationLocality = myChoize.dest.hasOwnProperty('loc') ? myChoize.dest.loc : MyChoizeAdddress.drop;
            submitData.data = selectedVendor.data;
            submitData.data.PickRegionKey = selectedVendor1.data.PickRegionKey;
            submitData.data.DropRegionKey = selectedVendor1.data.DropRegionKey;
            submitData.data.PickAddress = MyChoizeAdddress.pick;
            submitData.data.DropAddress = MyChoizeAdddress.drop;
            submitData.data.AdditionalService = selectedVendor1.data.AdditionalService;
            submitData.data.CreateBookingResponse = myChoizeBlockedVehicle.CreateBookingResponse;
            submitData.data.UserSessionCheckNewResponse = myChoizeBlockedVehicle.UserSessionCheckNewResponse;
            submitData.pricingRequestBean.stops = myChoize.stops;
        }


        console.log(submitData);
        axios.post(url, submitData)
            .then(response => {
                if (response.data.statusCD === 200) {
                    dispatch(logTransaction('Submit Bid Call Succeeded', submitData));
                    
                    console.log(response.data.responseData);
                    console.log(response.data.responseData.pricingRequestBean.ride);
                    dispatch(bidSubmitted());
                    dispatch(updateBidId({ bidId: response.data.responseData.bid.id, accountSeqId: response.data.responseData.pricingRequestBean.ride.accountSeqId }));
                    // dispatch(updateAdvanceAmount(response.data.responseData.advanceAmount));
                    success(response.data.responseData);// call back func
                }
                else if (response.data.statusCD === 404) {
                    console.log(response);
                    error(response);
                    dispatch(logTransaction('Submit Bid Call Succeeded', submitData));
                }
                else {
                    error(response);
                    console.log(response);
                    dispatch(logTransaction('Submit Bid Call Failed', submitData));
                }
            })
            .catch(err => {
                dispatch(logTransaction('Submit Bid Call Failed', submitData));
                error(err); //call back
            });
    }
}

export function cancelRide(success, error, tripId, cancelReason) {
    return function (dispatch, getState) {
        let url = urls.cancelUrl;
        let data = {
            "bidId": tripId,
            "cancelReason": cancelReason
        }
        console.log(data);
        axios.post(url, data)
            // console.log(res);
            .then(response => {
                
                if (response.data.statusCD === 200 && response.data.statusMSG === "Cancellation Successful") {
                    success(response.data.responseData);
                }
                else {

                }
            })
            .catch(err => {
                
                console.log(err);
                error();
            })
    }
}

export function updateScratchAmountProfile(success, error, bidId, customerId) {
    return function (dispatch, getState) {
        let url = urls.updateScratchAmountProfileUrl;
        let data = {
            "bidId": getState().order.bidId,
            "customerId": getState().user.profile.id
        }
        console.log(data);
        axios.post(url, data)
            // console.log(res);
            .then(response => {
                
                //  console.log(response);
                if (response.data.statusCD === 200) {
                    console.log('Profile Points Updated');
                }
                else {

                }
            })
            .catch(err => {
                
                console.log(err);
            })
    }
}

export function logTransaction(serviceName, json) {
    return function (dispatch, getState) {
        let url = urls.logTransaction;
        let data = {
            "serviceName": serviceName,
            "serviceInput": JSON.stringify(json),
            "uniqueId": getState().order.IP,
            "phoneNo": getState().user.phone,
            "source": "Website"

        }

        let ride = getState().ride;

        if (json.hasOwnProperty("pickupLocation")) {
            data.fromCity = ride.pickup.hasOwnProperty("city") ? ride.pickup.city : '';

            if (json.hasOwnProperty("destinationLocation")) {
                data.toCity = ride.dest.hasOwnProperty("city") ? ride.dest.city : ''
            }
        }

        console.log("logTransaction", data);
        axios.post(url, data)
            .then(response => {
                if (response.status === 200) {
                    console.log('transaction sent to the backend');
                    console.log(response);
                }
                else {
                    console.log('failed to send transaction to the backend');
                    console.log(response);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }
}

export function getIP() {
    return function (dispatch, getState) {
        let url = urls.ip;
        axios.get(url)
            .then(response => {
                console.log(response);
                if (response.status === 200) {
                    console.log('IP Address is' + response.data.ip);
                    dispatch(updateIP(response.data.ip));
                }
                else {
                    console.log('IP Address Failed' + response.data.ip);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }
}

export function blockMyChoizeVehicle(MyChoizeAdddress, selectedVendor1, data, success, error) {
    return function (dispatch, getState) {
        
        let url = urls.blockMyChoizeVehicle;
        let submitData;
        let ride = getState().ride;
        let stops = ride.stops;
        let selectedCar = getState().order.selectedCar;
        let selectedVendor = getState().order.selectedVendor;
        let user = getState().user;
        let couponCode = selectedVendor.coupon;
        let isChauffeurDriven = getState().ride.rentalType === 1 ? true : false;
        let isSelfDriven = getState().ride.rentalType === 2 ? true : false;
        let isAirport = getState().ride.rentalType === 3 ? true : false;
        let isRental = getState().ride.rentalType >= 4 ? true : false;
        let myChoize = getState().myChoize;
        // let paymentId = getState().order.paymentId;
        

        submitData = {
            "bid": {
                "bidAmount": selectedVendor.base_fare
            },
            "pricingRequestBean": {
                "pickupLocation": {
                    "latitude": ride.pickup.cityLat,
                    "longitude": ride.pickup.cityLng
                },
                // "pickupLocation": {
                //     "latitude": 17.385044,
                //     "longitude": 78.486671
                // },
                "destinationLocation": {
                    "latitude": ride.dest.hasOwnProperty("cityLat") && ride.dest.cityLat !== "" ? ride.dest.cityLat : ride.pickup.cityLat,
                    "longitude": ride.dest.hasOwnProperty("cityLng") && ride.dest.cityLng !== "" ? ride.dest.cityLng : ride.pickup.cityLng
                },
                "user": {
                    "name": user.name,
                    // "countryCode": this.state.countryCode,
                    "mobileNo": user.phone,
                    "emailId": user.email
                },
                "stops": stops,
                "ride": {
                    "isPortal": true,
                    "pickupCoordinates": ride.pickup.cityLat + "," + ride.pickup.cityLng,
                    "pickupLocality": ride.pickup.loc,
                    "destinationCoordinates": ride.pickup.cityLat + "," + ride.pickup.cityLng,
                    "destinationLocality": ride.dest.loc,
                    "rideStartDate": ride.startDate + " " + ride.startTime,
                    "rideEndDate": ride.endDate + " " + ride.endTime,
                    "distance": ride.distance,
                    "categoryId": selectedCar.category_id,
                    "subCategoryId": selectedCar.sub_category_id,
                    "accountId": selectedVendor.account_id,
                    "couponCode": couponCode,
                    "redeemedPoints": data.redeemedPoints,
                    "transmissionTypeId": selectedVendor.transmission_type_id,
                    "fuelTypeId": selectedVendor.fuel_type_id
                }
            }
        }
        console.log(selectedVendor1);
        submitData.pricingRequestBean.ride.measureLookupId = selectedVendor.measure_lookup_id;
        submitData.pricingRequestBean.user.licenseNo = user.licenseNo;
        submitData.pricingRequestBean.user.aadharNo = user.aadharNo;
        submitData.pricingRequestBean.user.panNumber = user.panNumber;
        submitData.pricingRequestBean.user.passportNo = user.passportNo;
        submitData.pricingRequestBean.user.licenseFront = user.licenseFront;
        submitData.pricingRequestBean.user.licenseBack = user.licenseBack;
        submitData.pricingRequestBean.user.passportFirstPage = user.passportDetailsPage;
        submitData.pricingRequestBean.user.aadharFront = user.aadharFront;
        submitData.pricingRequestBean.user.aadharBack = user.aadharBack;
        submitData.pricingRequestBean.user.panCard = user.panCard;
        submitData.pricingRequestBean.user.utilityNo = user.utilityNo;
        submitData.pricingRequestBean.user.utilityBill = user.utilityBill;
        // submitData.pricingRequestBean.ride.pickupCoordinates = myChoize.pickup.locLat + "," + myChoize.pickup.locLng;
        submitData.pricingRequestBean.ride.pickupLocality = myChoize.pickup.hasOwnProperty('loc') ? myChoize.pickup.loc : MyChoizeAdddress.pick;
        // submitData.pricingRequestBean.ride.destinationCoordinates = myChoize.dest.locLat + "," + myChoize.dest.locLng;
        submitData.pricingRequestBean.ride.destinationLocality = myChoize.dest.hasOwnProperty('loc') ? myChoize.dest.loc : MyChoizeAdddress.drop;
        submitData.data = selectedVendor.data;
        submitData.data.PickRegionKey = selectedVendor1.data.PickRegionKey;
        submitData.data.DropRegionKey = selectedVendor1.data.DropRegionKey;
        submitData.data.PickAddress = MyChoizeAdddress.pick;
        submitData.data.DropAddress = MyChoizeAdddress.drop;
        submitData.data.AdditionalService = selectedVendor1.data.AdditionalService;


        console.log("Block MyChoize Vehicle Data", submitData);
        axios.post(url, submitData)
            .then(response => {
                if (response.data.statusCD === 200) {
                    dispatch(logTransaction('Block MyChoize Vehicle Call Succeeded with statusCD 200', submitData));
                    console.log(response)
                    success(response.data.responseData);// call back func
                }
                else if (response.data.statusCD === 422) {
                    dispatch(logTransaction('Block MyChoize Vehicle Call Succeeded with statusCD 422', submitData));
                    console.log(response)
                    error(response);
                }
            })
            .catch(err => {
                dispatch(logTransaction('Block MyChoize Vehicle Call Failed', submitData));
                error(); //call back
            });
    }
}

export function placeHolderOrder(MyChoizeAdddress, selectedVendor1, myChoizeBlockedVehicle, success, error) {
    return function (dispatch, getState) {
        
        let url = urls.insertPlaceHolderOrder;
        let submitData;
        let ride = getState().ride;
        let stops = ride.stops;
        let selectedCar = getState().order.selectedCar;
        let selectedVendor = getState().order.selectedVendor;
        let user = getState().user;
        let couponCode = selectedVendor.coupon;
        let isChauffeurDriven = getState().ride.rentalType === 1 ? true : false;
        let isSelfDriven = getState().ride.rentalType === 2 ? true : false;
        let isAirport = getState().ride.rentalType === 3 ? true : false;
        let isRental = getState().ride.rentalType >= 4 ? true : false;
        let myChoize = getState().myChoize;
        

        submitData = {
            "bid": {
                "bidAmount": selectedVendor.base_fare
            },
            "pricingRequestBean": {
                "pickupLocation": {
                    "latitude": ride.pickup.cityLat,
                    "longitude": ride.pickup.cityLng
                },
                // "pickupLocation": {
                //     "latitude": 17.385044,
                //     "longitude": 78.486671
                // },
                "destinationLocation": {
                    "latitude": ride.dest.hasOwnProperty("cityLat") && ride.dest.cityLat !== "" ? ride.dest.cityLat : ride.pickup.cityLat,
                    "longitude": ride.dest.hasOwnProperty("cityLng") && ride.dest.cityLng !== "" ? ride.dest.cityLng : ride.pickup.cityLng
                },
                "user": {
                    "name": user.name,
                    // "countryCode": this.state.countryCode,
                    "mobileNo": user.phone,
                    "emailId": user.email
                },
                "stops": stops,
                "ride": {
                    "isPortal": true,
                    "pickupCoordinates": ride.pickup.locLat + "," + ride.pickup.locLng,
                    "pickupLocality": ride.pickup.loc,
                    "destinationCoordinates": ride.dest.locLat + "," + ride.dest.locLng,
                    "destinationLocality": ride.dest.loc,
                    "rideStartDate": ride.startDate + " " + ride.startTime,
                    "rideEndDate": ride.endDate + " " + ride.endTime,
                    "distance": ride.distance,
                    "categoryId": selectedCar.category_id,
                    "subCategoryId": selectedCar.sub_category_id,
                    "accountId": selectedVendor.account_id,
                    "couponCode": couponCode,
                    "redeemedPoints": 0,
                    "transmissionTypeId": selectedVendor.transmission_type_id,
                    "fuelTypeId": selectedVendor.fuel_type_id,
                    "travelerName": user.name,
                    "travelerContactNo": user.phone,
                    "travelerEmailId": user.email,
                    "fromCity": ride.pickup.city,
                    "toCity": ride.dest.city
                }
            },
            data: {
                modelId: selectedVendor.model_id,
                startDate: ride.startDate + " " + ride.startTime,
                endDate: ride.endDate + " " + ride.endTime,
            }
        }

        if (isChauffeurDriven && ride.measureLookupId === '100010011' && selectedCar.category !== "SELF DRIVE") {
            submitData.pricingRequestBean.ride.measureLookupId = ride.measureLookupId;
            submitData.pricingRequestBean.ride.rideEndDate = ride.startDate + ' 23:59:00'

        }
        else if (isChauffeurDriven) {
            submitData.pricingRequestBean.ride.measureLookupId = ride.measureLookupId;
        }
        else if (isAirport) {
            // let isAirportPickup = ride.pickup.loc.includes("Airport") || ride.pickup.loc.includes("airport") ? true : false;
            // let isAirportDrop = ride.dest.loc.includes("Airport") || ride.dest.loc.includes("airport") ? true : false;
            let isAirportPickup = ride.airportType === 'Airport Pickup' ? true : false;
            let isAirportDrop = ride.airportType === 'Airport Dropoff' ? true : false;
            submitData.pricingRequestBean.ride.measureLookupId = isAirportPickup ? 100010014 : (isAirportDrop ? 100010015 : 1111188111);
            // submitData.pricingRequestBean.destinationLocation.latitude = isAirportDrop ? ride.dest.locLat : ride.dest.cityLat;
            // submitData.pricingRequestBean.destinationLocation.longitude = isAirportDrop ? ride.dest.locLng : ride.dest.cityLng;
            delete submitData.pricingRequestBean.ride.rideEndDate; 
            submitData.pricingRequestBean.ride.pickupCoordinates = myChoize.pickup.locLat + "," + myChoize.pickup.locLng;
            submitData.pricingRequestBean.ride.pickupLocality = myChoize.pickup.hasOwnProperty('loc') ? myChoize.pickup.loc : MyChoizeAdddress.pick;
            submitData.pricingRequestBean.ride.destinationCoordinates = myChoize.dest.locLat + "," + myChoize.dest.locLng;
            submitData.pricingRequestBean.ride.destinationLocality = myChoize.dest.hasOwnProperty('loc') ? myChoize.dest.loc : MyChoizeAdddress.drop;  
            submitData.pricingRequestBean.stops = myChoize.stops;  
            submitData.pricingRequestBean.ride.distance = myChoize.distance;   
        }
        else if (isRental) {
            let measureLookup;
            // submitData.pricingRequestBean.pickupLocation.latitude=ride.pickup.locLat;
            // submitData.pricingRequestBean.pickupLocation.longitude=ride.pickup.locLng;
            // submitData.pricingRequestBean.destinationLocation.latitude=ride.dest.locLat;
            // submitData.pricingRequestBean.destinationLocation.longitude=ride.dest.locLng;
            delete submitData.pricingRequestBean.ride.distance;
            if (ride.rentalType === 4) {
                measureLookup = 100010009
                // 2 hrs
            }
            else if (ride.rentalType === 5) {
                measureLookup = 100010006
                // 4 hrs
            }
            else if (ride.rentalType === 6) {
                measureLookup = 100010007
                // 8hours
            }
            else if (ride.rentalType === 7) {
                measureLookup = 100010008
                // 12hours
            }
            else {
                measureLookup = null
            }
            submitData.pricingRequestBean.ride.measureLookupId = measureLookup;
            submitData.pricingRequestBean.ride.pickupCoordinates = ride.pickup.cityLat + "," + ride.pickup.cityLng;
            submitData.pricingRequestBean.ride.pickupLocality = ride.pickup.city;
            // submitData.pricingRequestBean.ride.pickupCoordinates = myChoize.pickup.locLat + "," + myChoize.pickup.locLng;
            // submitData.pricingRequestBean.ride.pickupLocality = myChoize.pickup.hasOwnProperty('loc') ? myChoize.pickup.loc : MyChoizeAdddress.pick;
            // let stops = [];
            // stops.push(myChoize.stops[0]);
            // submitData.pricingRequestBean.stops = stops;
        }
        else {
            submitData.pricingRequestBean.ride.measureLookupId = selectedVendor.measure_lookup_id;
            submitData.pricingRequestBean.user.licenseNo = user.licenseNo;
            submitData.pricingRequestBean.user.aadharNo = user.aadharNo;
            submitData.pricingRequestBean.user.panNumber = user.panNumber;
            submitData.pricingRequestBean.user.passportNo = user.passportNo;
            submitData.pricingRequestBean.user.licenseFront = user.licenseFront;
            submitData.pricingRequestBean.user.licenseBack = user.licenseBack;
            submitData.pricingRequestBean.user.passportFirstPage = user.passportDetailsPage;
            submitData.pricingRequestBean.user.aadharFront = user.aadharFront;
            submitData.pricingRequestBean.user.aadharBack = user.aadharBack;
            submitData.pricingRequestBean.user.panCard = user.panCard;
            submitData.pricingRequestBean.user.utilityNo = user.utilityNo;
            submitData.pricingRequestBean.user.utilityBill = user.utilityBill;
            submitData.pricingRequestBean.ride.pickupCoordinates = ride.pickup.cityLat + "," + ride.pickup.cityLng;
            submitData.pricingRequestBean.ride.pickupLocality = ride.pickup.city;
            // let stops = [];
            // stops.push(myChoize.stops[0]);
            // submitData.pricingRequestBean.stops = stops;
        }
        if (selectedVendor.account_name === 'MyChoize') {
            console.log(selectedVendor1);
            submitData.pricingRequestBean.ride.pickupCoordinates = ride.pickup.cityLat + "," + ride.pickup.cityLng;
            submitData.pricingRequestBean.ride.pickupLocality = myChoize.pickup.hasOwnProperty('loc') ? myChoize.pickup.loc : MyChoizeAdddress.pick;
            submitData.pricingRequestBean.ride.destinationCoordinates = ride.pickup.cityLat + "," + ride.pickup.cityLng;
            submitData.pricingRequestBean.ride.destinationLocality = myChoize.dest.hasOwnProperty('loc') ? myChoize.dest.loc : MyChoizeAdddress.drop;
            submitData.data = selectedVendor.data;
            submitData.data.PickRegionKey = selectedVendor1.data.PickRegionKey;
            submitData.data.DropRegionKey = selectedVendor1.data.DropRegionKey;
            submitData.data.PickAddress = MyChoizeAdddress.pick;
            submitData.data.DropAddress = MyChoizeAdddress.drop;
            submitData.data.AdditionalService = selectedVendor1.data.AdditionalService;
            submitData.data.CreateBookingResponse = myChoizeBlockedVehicle.CreateBookingResponse;
            submitData.data.UserSessionCheckNewResponse = myChoizeBlockedVehicle.UserSessionCheckNewResponse;
            submitData.pricingRequestBean.stops = myChoize.stops;
        }
        if (selectedVendor.account_name === 'Zoomcar') {
            submitData.pricingRequestBean.pickupLocation.name = ride.pickup.city;
            let zoomData = {
                "modelId": selectedVendor.model_id,
                "pricingId": selectedVendor.pricing_id,
                "locationId": MyChoizeAdddress.locationId
            }
            submitData.data = zoomData;
        }

        console.log(JSON.stringify(submitData));
        axios.post(url, submitData)
            .then(response => {
                let placeHolderData = {
                    pickCity: ride.pickup.city,
                    dropCity: ride.dest.city,
                    pickAddress: ride.pickup.loc,
                    dropAddress: ride.dest.loc,
                    startDate: ride.startDate,
                    startTime: ride.startTime,
                    endDate: ride.endDate,
                    endTime: ride.endTime,
                    rentalType: ride.rentalType,
                    vendor: selectedVendor.account_name,
                    carCategotyId: selectedCar.category_id,
                    carSubCategoryId: selectedCar.sub_category_id
                };
                if (isChauffeurDriven) {
                    placeHolderData.type = ride.measureLookupId;
                }
                else if (isAirport) {
                    placeHolderData.type = ride.airportType;
                }
                else if (isRental) {
                    if (ride.rentalType === 4) {
                        placeHolderData.type = "2 hrs, 20 kms";
                        // 2 hrs
                    }
                    else if (ride.rentalType === 5) {
                        placeHolderData.type = "4 hrs, 40 kms";
                        // 4 hrs
                    }
                    else if (ride.rentalType === 6) {
                        placeHolderData.type = "8 hrs, 80 kms";
                        // 8hours
                    }
                    else if (ride.rentalType === 7) {
                        placeHolderData.type = "12 hrs, 120 kms";
                        // 12hours
                    }
                }
                else {
                    placeHolderData.type = selectedVendor.measure_cd
                }
                console.log("Place holder data", placeHolderData);
                if (response.data.statusCD === 200) {
                    dispatch(logTransaction('Place Holder Call Succeeded', submitData));
                    
                    console.log("Place holder respose", response);
                    dispatch(updateBidId({ bidId: response.data.responseData.bid.id, accountSeqId: response.data.responseData.pricingRequestBean.ride.accountSeqId }));
                    dispatch(UpdatePlaceHolderData(placeHolderData));
                    // dispatch(updateAdvanceAmount(response.data.responseData.advanceAmount));
                    // success(response.data.responseData);// call back func
                }
                else if (response.data.statusCD === 404) {
                    console.log("Place holder response", response);
                    dispatch(UpdatePlaceHolderData(placeHolderData));
                    // error(response);
                    dispatch(logTransaction('Place Holder Call Succeeded', submitData));
                }
                else {
                    // error();
                    console.log("Place holder response", response);
                    dispatch(UpdatePlaceHolderData(placeHolderData));
                    dispatch(logTransaction('Place Holder Call Failed', submitData));
                }
            })
            .catch(err => {
                dispatch(logTransaction('Place Holder Call Failed', submitData));
                // error(err); //call back
            });
    }
}

export function confirmPlaceHolderOrder(MyChoizeAdddress, selectedVendor1, myChoizeBlockedVehicle, data, success, error) {
    return function (dispatch, getState) {
        
        let url = urls.confirmPlaceHolderOrder;
        let submitData;
        let ride = getState().ride;
        let stops = ride.stops;
        let selectedCar = getState().order.selectedCar;
        let selectedVendor = getState().order.selectedVendor;
        let user = getState().user;
        let couponCode = selectedVendor.coupon;
        let isChauffeurDriven = getState().ride.rentalType === 1 ? true : false;
        let isSelfDriven = getState().ride.rentalType === 2 ? true : false;
        let isAirport = getState().ride.rentalType === 3 ? true : false;
        let isRental = getState().ride.rentalType >= 4 ? true : false;
        let myChoize = getState().myChoize;
        let paymentId = getState().order.paymentId;
        let bidId = getState().order.bidId;
        

        submitData = {
            "bid": {
                "bidAmount": selectedVendor.base_fare
            },
            "pricingRequestBean": {
                "pickupLocation": {
                    "latitude": ride.pickup.cityLat,
                    "longitude": ride.pickup.cityLng
                },
                // "pickupLocation": {
                //     "latitude": 17.385044,
                //     "longitude": 78.486671
                // },
                "destinationLocation": {
                    "latitude": ride.dest.hasOwnProperty("cityLat") && ride.dest.cityLat !== "" ? ride.dest.cityLat : ride.pickup.cityLat,
                    "longitude": ride.dest.hasOwnProperty("cityLng") && ride.dest.cityLng !== "" ? ride.dest.cityLng : ride.pickup.cityLng
                },
                "user": {
                    "name": user.name,
                    // "countryCode": this.state.countryCode,
                    "mobileNo": user.phone,
                    "emailId": user.email
                },
                "stops": stops,
                "ride": {
                    "bidId": Number(bidId),
                    "isPortal": true,
                    "pickupCoordinates": ride.pickup.locLat + "," + ride.pickup.locLng,
                    "pickupLocality": ride.pickup.loc,
                    "destinationCoordinates": ride.dest.locLat + "," + ride.dest.locLng,
                    "destinationLocality": ride.dest.loc,
                    "rideStartDate": ride.startDate + " " + ride.startTime,
                    "rideEndDate": ride.endDate + " " + ride.endTime,
                    "distance": ride.distance,
                    "categoryId": selectedCar.category_id,
                    "subCategoryId": selectedCar.sub_category_id,
                    "accountId": selectedVendor.account_id,
                    "couponCode": couponCode,
                    "redeemedPoints": data.redeemedPoints,
                    "transmissionTypeId": selectedVendor.transmission_type_id,
                    "fuelTypeId": selectedVendor.fuel_type_id,
                    "travelerName": user.name,
                    "travelerContactNo": user.phone,
                    "travelerEmailId": user.email,
                    "fromCity": ride.pickup.city,
                    "toCity": ride.dest.city
                }
            },
            data: {
                modelId: selectedVendor.model_id,
                startDate: ride.startDate + " " + ride.startTime,
                endDate: ride.endDate + " " + ride.endTime,
            }
        }


        if (isChauffeurDriven && ride.measureLookupId === '100010011' && selectedCar.category !== "SELF DRIVE") {
            submitData.pricingRequestBean.ride.measureLookupId = ride.measureLookupId;
            submitData.pricingRequestBean.ride.rideEndDate = ride.startDate + ' 23:59:00'
            submitData.pricingRequestBean.ride.paymentId = paymentId

        }
        else if (isChauffeurDriven) {
            submitData.pricingRequestBean.ride.measureLookupId = ride.measureLookupId;
            submitData.pricingRequestBean.ride.paymentId = paymentId
        }
        else if (isAirport) {
            // let isAirportPickup = ride.pickup.loc.includes("Airport") || ride.pickup.loc.includes("airport") ? true : false;
            // let isAirportDrop = ride.dest.loc.includes("Airport") || ride.dest.loc.includes("airport") ? true : false;
            let isAirportPickup = ride.airportType === 'Airport Pickup' ? true : false;
            let isAirportDrop = ride.airportType === 'Airport Dropoff' ? true : false;
            submitData.pricingRequestBean.ride.measureLookupId = isAirportPickup ? 100010014 : (isAirportDrop ? 100010015 : 1111188111);
            submitData.pricingRequestBean.ride.paymentId = paymentId;
            // submitData.pricingRequestBean.destinationLocation.latitude = isAirportDrop ? ride.dest.locLat : ride.dest.cityLat;
            // submitData.pricingRequestBean.destinationLocation.longitude = isAirportDrop ? ride.dest.locLng : ride.dest.cityLng;
            delete submitData.pricingRequestBean.ride.rideEndDate; 
            submitData.pricingRequestBean.ride.pickupCoordinates = myChoize.pickup.locLat + "," + myChoize.pickup.locLng;
            submitData.pricingRequestBean.ride.pickupLocality = myChoize.pickup.hasOwnProperty('loc') ? myChoize.pickup.loc : MyChoizeAdddress.pick;
            submitData.pricingRequestBean.ride.destinationCoordinates = myChoize.dest.locLat + "," + myChoize.dest.locLng;
            submitData.pricingRequestBean.ride.destinationLocality = myChoize.dest.hasOwnProperty('loc') ? myChoize.dest.loc : MyChoizeAdddress.drop;  
            submitData.pricingRequestBean.stops = myChoize.stops;  
            submitData.pricingRequestBean.ride.distance = myChoize.distance;   
        }
        else if (isRental) {
            let measureLookup;
            // submitData.pricingRequestBean.pickupLocation.latitude=ride.pickup.locLat;
            // submitData.pricingRequestBean.pickupLocation.longitude=ride.pickup.locLng;
            // submitData.pricingRequestBean.destinationLocation.latitude=ride.dest.locLat;
            // submitData.pricingRequestBean.destinationLocation.longitude=ride.dest.locLng;
            delete submitData.pricingRequestBean.ride.distance;
            if (ride.rentalType === 4) {
                measureLookup = 100010009
                // 2 hrs
            }
            else if (ride.rentalType === 5) {
                measureLookup = 100010006
                // 4 hrs
            }
            else if (ride.rentalType === 6) {
                measureLookup = 100010007
                // 8hours
            }
            else if (ride.rentalType === 7) {
                measureLookup = 100010008
                // 12hours
            }
            else {
                measureLookup = null
            }
            submitData.pricingRequestBean.ride.measureLookupId = measureLookup;
            submitData.pricingRequestBean.ride.paymentId = paymentId;
            submitData.pricingRequestBean.ride.pickupCoordinates = ride.pickup.cityLat + "," + ride.pickup.cityLng;
            submitData.pricingRequestBean.ride.pickupLocality = ride.pickup.city;
            // submitData.pricingRequestBean.ride.pickupCoordinates = myChoize.pickup.locLat + "," + myChoize.pickup.locLng;
            // submitData.pricingRequestBean.ride.pickupLocality = myChoize.pickup.hasOwnProperty('loc') ? myChoize.pickup.loc : MyChoizeAdddress.pick;
            // let stops = [];
            // stops.push(myChoize.stops[0]);
            // submitData.pricingRequestBean.stops = stops;
        }
        else {
            submitData.pricingRequestBean.ride.measureLookupId = selectedVendor.measure_lookup_id;
            submitData.pricingRequestBean.ride.paymentId = paymentId;
            submitData.pricingRequestBean.user.licenseNo = user.licenseNo;
            submitData.pricingRequestBean.user.aadharNo = user.aadharNo;
            submitData.pricingRequestBean.user.panNumber = user.panNumber;
            submitData.pricingRequestBean.user.passportNo = user.passportNo;
            submitData.pricingRequestBean.user.licenseFront = user.licenseFront;
            submitData.pricingRequestBean.user.licenseBack = user.licenseBack;
            submitData.pricingRequestBean.user.passportFirstPage = user.passportDetailsPage;
            submitData.pricingRequestBean.user.aadharFront = user.aadharFront;
            submitData.pricingRequestBean.user.aadharBack = user.aadharBack;
            submitData.pricingRequestBean.user.panCard = user.panCard;
            submitData.pricingRequestBean.user.utilityNo = user.utilityNo;
            submitData.pricingRequestBean.user.utilityBill = user.utilityBill;
            submitData.pricingRequestBean.ride.pickupCoordinates = ride.pickup.cityLat + "," + ride.pickup.cityLng;
            submitData.pricingRequestBean.ride.pickupLocality = ride.pickup.city;
            // let stops = [];
            // stops.push(myChoize.stops[0]);
            // submitData.pricingRequestBean.stops = stops;
            if (selectedVendor.account_name === 'Driven') {
                if (ride.pickup.city === 'Hyderabad' || ride.pickup.city === 'Secunderabad') {
                    submitData.pricingRequestBean.ride.pickupCoordinates = 17.429230 + "," + 78.421560;
                    submitData.pricingRequestBean.ride.pickupLocality = '82, Rd Number 7, Women\'s Welfare Housing Society, Jubilee Hills,Hyderabad, Telangana 500033, India';
                }
                if (ride.pickup.city === 'Bangalore' || ride.pickup.city === 'Bengaluru Rural' || ride.pickup.city === 'Bengaluru Urban' || ride.pickup.city === 'Bangalore Rural' || ride.pickup.city === 'Bangalore Urban' || ride.pickup.city === 'Bengaluru') {
                    submitData.pricingRequestBean.ride.pickupCoordinates = 12.943410 + "," + 77.562740;
                    submitData.pricingRequestBean.ride.pickupLocality = '621, 4th Cross Rd, near Sony signals, 6th Block, Koramangala, Bengaluru, Karnataka 560095, India';
                }
                if (ride.pickup.city === 'Chennai') {
                    submitData.pricingRequestBean.ride.pickupCoordinates = 13.062130 + "," + 80.112910;
                    submitData.pricingRequestBean.ride.pickupLocality = '107/2A1, Poonamallee High Rd, Senneer Kuppam, Goparasanallur,Chennai, Tamil Nadu 600056, India';
                }
                if (ride.pickup.city === 'Vizag' || ride.pickup.city === 'Visakhapatnam') {
                    submitData.pricingRequestBean.ride.pickupCoordinates = 17.724084 + "," + 83.33953;
                    submitData.pricingRequestBean.ride.pickupLocality = 'The Park, Visakhapatnam, Beach Rd, near VUDA Park, Jalari Peta, Visakhapatnam, Andhra Pradesh 530023';
                }
            }
        }
        if (selectedVendor.account_name === 'MyChoize') {
            console.log(selectedVendor1);
            submitData.pricingRequestBean.ride.pickupCoordinates = ride.pickup.cityLat + "," + ride.pickup.cityLng;
            submitData.pricingRequestBean.ride.pickupLocality = myChoize.pickup.hasOwnProperty('loc') ? myChoize.pickup.loc : MyChoizeAdddress.pick;
            submitData.pricingRequestBean.ride.destinationCoordinates = ride.pickup.cityLat + "," + ride.pickup.cityLng;
            submitData.pricingRequestBean.ride.destinationLocality = myChoize.dest.hasOwnProperty('loc') ? myChoize.dest.loc : MyChoizeAdddress.drop;
            submitData.data = selectedVendor.data;
            submitData.data.PickRegionKey = selectedVendor1.data.PickRegionKey;
            submitData.data.DropRegionKey = selectedVendor1.data.DropRegionKey;
            submitData.data.PickAddress = MyChoizeAdddress.pick;
            submitData.data.DropAddress = MyChoizeAdddress.drop;
            submitData.data.AdditionalService = selectedVendor1.data.AdditionalService;
            submitData.data.CreateBookingResponse = myChoizeBlockedVehicle.CreateBookingResponse;
            submitData.data.UserSessionCheckNewResponse = myChoizeBlockedVehicle.UserSessionCheckNewResponse;
            submitData.pricingRequestBean.stops = myChoize.stops;
        }
        if (selectedVendor.account_name === 'Zoomcar') {
            submitData.pricingRequestBean.pickupLocation.name = ride.pickup.city;
            let zoomData = {
                "modelId": selectedVendor.model_id,
                "pricingId": selectedVendor.pricing_id,
                "locationId": MyChoizeAdddress.locationId
            }
            submitData.data = zoomData;
        }

        console.log("Confirm Place Holder Order Data", submitData);
        axios.post(url, submitData)
            .then(response => {
                if (response.data.statusCD === 200) {
                    dispatch(logTransaction('Confirm Place Holder Order Call Succeeded', submitData));
                    
                    console.log(response.data.responseData);
                    console.log(response.data.responseData.pricingRequestBean.ride);
                    dispatch(bidSubmitted());
                    dispatch(updateBidId({ bidId: response.data.responseData.bid.id, accountSeqId: response.data.responseData.pricingRequestBean.ride.accountSeqId }));
                    // dispatch(updateAdvanceAmount(response.data.responseData.advanceAmount));
                    success(response.data.responseData);// call back func
                }
                else if (response.data.statusCD === 404) {
                    console.log(response);
                    error(response);
                    dispatch(logTransaction('Confirm Place Holder Order Call Succeeded', submitData));
                }
                else {
                    error();
                    console.log(response);
                    dispatch(logTransaction('Confirm Place Holder Order Call Failed', submitData));
                }
            })
            .catch(err => {
                dispatch(logTransaction('Confirm Place Holder Order Call Failed', submitData));
                error(err); //call back
            });
    }
}

export function cancelInitiate(tripId, success, error) {
    return function (dispatch, getState) {
        let url = urls.cancelInitiate;
        let data = {
            "bidId": tripId,
        }
        console.log(data);
        axios.post(url, data)
            .then(response => {
                console.log("Cancel Initiate Response", response)
                if (response.data.statusCD === 200) {
                    success(response.data.responseData);
                }
                else {
                    error();
                }
            })
            .catch(err => {
                console.log(err);
                error();
            })
    }
}